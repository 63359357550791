import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';

import { Link } from 'gatsby';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import { BookingItemListItemBookingFragment } from '../generated/graphql';
import { helloLevelToCefrLevel } from '../util';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    cursor: 'pointer',
    borderLeft: '6px solid transparent',
    marginBottom: theme.spacing(1),

    '&:hover': {
      borderLeft: '6px solid blue',
    },
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    width: 180,
  },
  mainContentContainer: {
    marginLeft: theme.spacing(2),
    width: '100%',
  },
  mainContentTitleContainer: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  mainContentItemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  mainContentInfoItem: {
    minWidth: 200,
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  mainContentInfoItemTitle: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#747474',
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export const bookingItemListItemBooking = gql`
  fragment bookingItemListItemBooking on ClassBooking {
    id
    status
    created
    bookingTimeslot {
      startTime
      durationInMinutes
    }
    classTopic {
      id
      title
    }
    teacher {
      id
      email
    }
    user {
      id
      name
      progress {
        sectionProgress {
          section {
            lesson {
              level {
                number
              }
            }
          }
        }
      }
    }
  }
`;

interface BookingItemListItemProps {
  booking: BookingItemListItemBookingFragment;
}
export const BookingItemListItem = ({ booking }: BookingItemListItemProps) => {
  const styles = useStyles();

  const startTime = DateTime.fromISO(booking.bookingTimeslot.startTime);
  const endTime = startTime.plus({
    minutes: booking.bookingTimeslot.durationInMinutes,
  });
  const timeSinceCreated = DateTime.fromISO(booking.created).diffNow().negate();
  const level =
    booking.user?.progress?.sectionProgress?.section.lesson.level.number;

  return (
    <Link to={`/booking/${booking.id}`} className={styles.link}>
      <Paper className={styles.container}>
        <div className={styles.timeContainer}>
          <div>{startTime.toFormat("cccc',' LLL dd")}</div>
          <div>{`${startTime.toFormat('T')} - ${endTime.toFormat('T')}`}</div>
        </div>
        <div className={styles.mainContentContainer}>
          <div className={styles.mainContentTitleContainer}>
            {booking.classTopic?.title}
          </div>
          <div className={styles.mainContentItemsContainer}>
            <div className={styles.mainContentInfoItem}>
              <div className={styles.mainContentInfoItemTitle}>Status</div>
              {`${booking.status}`}
            </div>
            <div className={styles.mainContentInfoItem}>
              <div className={styles.mainContentInfoItemTitle}>
                Time since booked
              </div>
              {`${timeSinceCreated.toFormat("d'd' h'h' m'm'")}`}
            </div>
            <div className={styles.mainContentInfoItem}>
              <div className={styles.mainContentInfoItemTitle}>User</div>
              {`${booking.user?.name}`}
            </div>

            <div className={styles.mainContentInfoItem}>
              <div className={styles.mainContentInfoItemTitle}>Hello Level</div>
              {level}
            </div>
            <div className={styles.mainContentInfoItem}>
              <div className={styles.mainContentInfoItemTitle}>CEFR Level</div>
              {level ? `${helloLevelToCefrLevel(level)}` : ''}
            </div>
            <div className={styles.mainContentInfoItem}>
              <div className={styles.mainContentInfoItemTitle}>Teacher</div>
              {`${booking.teacher?.email}`}
            </div>
          </div>
        </div>
      </Paper>
    </Link>
  );
};
