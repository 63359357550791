export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A date string, such as 2007-12-03, compliant with the `full-date` format
   * outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for
   * representation of dates and times using the Gregorian calendar.
   **/
  Date: any;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   **/
  DateTime: any;
  /** The `Guid` scalar type represents the [EF Carbon Primitive](https://github.com/ef-carbon/primitive) GUID. */
  Guid: any;
  /** The `Discourse` scalar type represents [EF Carbon Conversation](https://github.com/ef-carbon/primitive) Discourse. */
  DiscourseDom: any;
  /** The `Link` scalar type represents a [EF Carbon Conversation](https://github.com/ef-carbon/primitive) Link. */
  Link: any;
  /** The `FormationMeta` scalar type represents the meta information on a formation. */
  FormationMeta: any;
  /** The `Dom` scalar type represents the [EF Carbon DOM](https://github.com/ef-carbon/dom) tree. */
  Dom: any;
}

export interface Admin {
  __typename?: 'Admin';
  /** The ID of an object */
  id: Scalars['ID'];
  email: Scalars['String'];
  role: AdminRole;
  permissions: Array<AdminPermission>;
}

export interface AdminAddCreditsInput {
  userId: Scalars['ID'];
  /** The amount of credits to give the user. A negative amount will result in removing credits from the user. */
  credits: Scalars['Int'];
  /** The reason for giving the user credits */
  reason: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminAddCreditsPayload {
  __typename?: 'AdminAddCreditsPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminBookingsLookup {
  __typename?: 'AdminBookingsLookup';
  result: ClassBookingConnection;
}

export interface AdminCancelClassBookingInput {
  classBookingId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminCancelClassBookingPayload {
  __typename?: 'AdminCancelClassBookingPayload';
  booking?: Maybe<ClassBooking>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminCreateClassBookingNoteInput {
  classBookingId: Scalars['ID'];
  /** Note that will be visible to the student */
  note: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminCreateClassBookingNotePayload {
  __typename?: 'AdminCreateClassBookingNotePayload';
  note?: Maybe<ClassBookingNote>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminCreatePromoCodeInput {
  /** The product the voucher provides */
  productId: PromoCodeProduct;
  /** Name for the promo code */
  title: Scalars['String'];
  /** How many times the promo code can be used */
  maxRedemptions: Scalars['Int'];
  /** Why this promo code is created */
  reason: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminCreatePromoCodePayload {
  __typename?: 'AdminCreatePromoCodePayload';
  promoCode: PromoCode;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminEmailLookup {
  __typename?: 'AdminEmailLookup';
  result?: Maybe<Admin>;
}

export enum AdminPermission {
  ReadUserData = 'READ_USER_DATA',
  ReadSensitiveUserData = 'READ_SENSITIVE_USER_DATA',
  ReadUserMessages = 'READ_USER_MESSAGES',
  ManageUserPremium = 'MANAGE_USER_PREMIUM',
  ManageUserCredits = 'MANAGE_USER_CREDITS',
  ManagePromoCodes = 'MANAGE_PROMO_CODES',
  ReadUserStats = 'READ_USER_STATS',
  ManageUserLevel = 'MANAGE_USER_LEVEL',
  ReadClassBooking = 'READ_CLASS_BOOKING',
  ManageClassBooking = 'MANAGE_CLASS_BOOKING',
  ReadCourseware = 'READ_COURSEWARE',
  ManageAdmins = 'MANAGE_ADMINS',
}

export interface AdminQuery {
  __typename?: 'AdminQuery';
  chatMessageSearch?: Maybe<ChatMessageSearch>;
  userIdLookup?: Maybe<UserIdLookup>;
  userPhoneLookup?: Maybe<UserPhoneLookup>;
  adminEmailLookup?: Maybe<AdminEmailLookup>;
  lessonProgress?: Maybe<LessonProgress>;
  adminBookingsLookup?: Maybe<AdminBookingsLookup>;
  /** All promo codes created by admins */
  adminPromoCodesLookup: PromoCodeConnection;
}

export interface AdminQueryChatMessageSearchArgs {
  query?: Maybe<Scalars['String']>;
}

export interface AdminQueryUserIdLookupArgs {
  userId: Scalars['String'];
}

export interface AdminQueryUserPhoneLookupArgs {
  countryCode: Scalars['String'];
  phoneNumber: Scalars['String'];
}

export interface AdminQueryAdminEmailLookupArgs {
  email: Scalars['String'];
}

export interface AdminQueryLessonProgressArgs {
  lessonId: Scalars['ID'];
  userId: Scalars['ID'];
}

export interface AdminQueryAdminBookingsLookupArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  startingAfter?: Maybe<Scalars['DateTime']>;
}

export interface AdminQueryAdminPromoCodesLookupArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  sourceKind?: Maybe<PromoCodeSourceKind>;
  orderId?: Maybe<Scalars['String']>;
}

export interface AdminRedeemPromoCodeWithPhoneNumberInput {
  /** The promo code */
  code: Scalars['String'];
  /** The users phone number */
  phoneNumber: Scalars['String'];
  /** The users country code */
  countryCode: Scalars['String'];
  /** Why this promo code was applied to this user */
  reason: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminRedeemPromoCodeWithPhoneNumberPayload {
  __typename?: 'AdminRedeemPromoCodeWithPhoneNumberPayload';
  user: User;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminRedeemPromoCodeWithUserIdInput {
  /** The promo code */
  code: Scalars['String'];
  /** The users ID */
  userId: Scalars['String'];
  /** Why this promo code was applied to this user */
  reason: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminRedeemPromoCodeWithUserIdPayload {
  __typename?: 'AdminRedeemPromoCodeWithUserIdPayload';
  user: User;
  clientMutationId?: Maybe<Scalars['String']>;
}

export enum AdminRole {
  FullAdmin = 'FULL_ADMIN',
  Teacher = 'TEACHER',
  HeadTeacher = 'HEAD_TEACHER',
  Basic = 'BASIC',
}

export interface AdminUpdateAdminInput {
  adminId: Scalars['ID'];
  /** Updated admin role */
  role?: Maybe<AdminRole>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdateAdminPayload {
  __typename?: 'AdminUpdateAdminPayload';
  admin?: Maybe<Admin>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdatePromoCodeInput {
  /** The promo code ID */
  id: Scalars['ID'];
  status?: Maybe<PromoCodeStatus>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdatePromoCodePayload {
  __typename?: 'AdminUpdatePromoCodePayload';
  promoCode: PromoCode;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdateUserLevelInput {
  userId: Scalars['ID'];
  /** The new level for the user */
  levelNumber: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdateUserLevelPayload {
  __typename?: 'AdminUpdateUserLevelPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpgradeUserInput {
  userId: Scalars['ID'];
  /** Voucher product id - this will determine the length of the premium upgrade */
  product: VoucherProduct;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpgradeUserPayload {
  __typename?: 'AdminUpgradeUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AppleIap {
  __typename?: 'AppleIAP';
  premiumPricePlans: Array<PremiumPricePlanAppStore>;
}

export interface AskATeacherTicket {
  __typename?: 'AskATeacherTicket';
  /** The ID of an object */
  id: Scalars['ID'];
  sendbirdId: Scalars['Int'];
  sendbirdChannelUrl: Scalars['String'];
}

/** User response to the assessment */
export interface AssessmentResponse {
  /** A string describing the users proficiency in english, as picked by the user */
  selfAssessedLevel: Scalars['String'];
  /** An array of responses to assessment questions */
  responses?: Maybe<Array<Maybe<ResponseInput>>>;
}

/** The result of the user being assessed */
export interface AssessmentResult {
  __typename?: 'AssessmentResult';
  /** The level that the user has assessed themself at. */
  selfAssessedLevel: Scalars['Int'];
  /** The level that the user has been assigned. */
  determinedLevel: Scalars['Int'];
  /** The level estimated for the user, based on their responses to the assessment questions */
  estimatedLevel?: Maybe<Scalars['Int']>;
  /** The status of the request to the assessment engine */
  estimationStatus?: Maybe<EstimationStatus>;
  /** Whether or not the user skipped the assessment */
  skipped?: Maybe<Scalars['Boolean']>;
  /** An array of responses to assessment questions */
  responses?: Maybe<Array<Maybe<Response>>>;
}

/** Returns the necessary information to start an assessment */
export interface AssessmentStartInfo {
  __typename?: 'AssessmentStartInfo';
  /** The id of the first formation in the discourse */
  formationStartId: Scalars['String'];
}

/** A chat for the user to interact with the assistant */
export interface AssistantChat extends Chat, Node {
  __typename?: 'AssistantChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
  /** The routine for this assistant */
  routine: AssistantChatRoutine;
  /** The id of the first formation for the conversation */
  formationStartId: Scalars['String'];
}

/** The routine of assistant chat */
export enum AssistantChatRoutine {
  /** Legacy assistant */
  Legacy = 'Legacy',
  /** An assistant to find language buddies for user */
  LanguageBuddy = 'LanguageBuddy',
}

export interface AudioCarouselMessageItem {
  __typename?: 'AudioCarouselMessageItem';
  items: Array<AudioMessageItem>;
}

export interface AudioCarouselReviewContentItem {
  __typename?: 'AudioCarouselReviewContentItem';
  items: Array<AudioReviewContentItem>;
}

export interface AudioMessageItem {
  __typename?: 'AudioMessageItem';
  url: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
  transcript?: Maybe<Scalars['String']>;
  transcripts?: Maybe<Array<AudioMessageItemTranscriptItem>>;
}

export interface AudioMessageItemTranscriptItem {
  __typename?: 'AudioMessageItemTranscriptItem';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface AudioReviewContentItem {
  __typename?: 'AudioReviewContentItem';
  url: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
  transcripts?: Maybe<Array<AudioReviewContentItemTranscript>>;
}

export interface AudioReviewContentItemTranscript {
  __typename?: 'AudioReviewContentItemTranscript';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

/** Information about the current backend server */
export interface BackendInfo {
  __typename?: 'BackendInfo';
  /** Full name of the server */
  fullName?: Maybe<Scalars['String']>;
  /** Enviroment the backend is running in */
  env?: Maybe<Scalars['String']>;
  /** Region the server is deployed */
  region?: Maybe<Scalars['String']>;
  /** Version of the formations */
  formationsVersion?: Maybe<Scalars['Int']>;
}

/** Upload a file by base64 encoding the content into a string */
export interface Base64Upload {
  /** Base64 encoded file */
  base64: Scalars['String'];
}

export interface BaseStudySection extends StudySection {
  __typename?: 'BaseStudySection';
  /** The ID of an object */
  id: Scalars['ID'];
  dialog: Dialog;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  token: Scalars['String'];
}

export interface BookingTimeslot {
  __typename?: 'BookingTimeslot';
  token: Scalars['String'];
  startTime: Scalars['DateTime'];
  /** duration in minutes */
  duration: Scalars['Int'];
  /** duration in minutes */
  durationInMinutes: Scalars['Int'];
}

export interface BookmarkWordInput {
  word: Scalars['String'];
  /** if true, create the bookmark, else delete the bookmark */
  bookmark: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface BookmarkWordPayload {
  __typename?: 'BookmarkWordPayload';
  viewer: Viewer;
  dictionaryWord?: Maybe<DictionaryWord>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface BucketAssignment {
  __typename?: 'BucketAssignment';
  bucketGroup: Scalars['String'];
  bucket: Scalars['String'];
}

/** A chat for the user to report problems with the app */
export interface BugReportingChat extends Chat, Node {
  __typename?: 'BugReportingChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
}

export interface CardCarouselMessageItem {
  __typename?: 'CardCarouselMessageItem';
  items: Array<CardMessageItem>;
}

export interface CardCarouselReviewContentItem {
  __typename?: 'CardCarouselReviewContentItem';
  items: Array<CardReviewContentItem>;
}

export interface CardMessageItem {
  __typename?: 'CardMessageItem';
  title: CardMessageItemTitle;
  body: Scalars['String'];
  items: Array<CardMessageItemBodyItem>;
  imageUrl?: Maybe<Scalars['String']>;
  image?: Maybe<CardMessageItemImage>;
  audio?: Maybe<CardMessageItemAudio>;
  subTitle?: Maybe<Scalars['String']>;
  subtitle?: Maybe<CardMessageItemSubtitle>;
}

export interface CardMessageItemAudio {
  __typename?: 'CardMessageItemAudio';
  url: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
}

export interface CardMessageItemBodyImage {
  __typename?: 'CardMessageItemBodyImage';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export type CardMessageItemBodyItem =
  | CardMessageItemBodyImage
  | CardMessageItemBodyText;

export interface CardMessageItemBodyText {
  __typename?: 'CardMessageItemBodyText';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface CardMessageItemImage {
  __typename?: 'CardMessageItemImage';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export interface CardMessageItemSubtitle {
  __typename?: 'CardMessageItemSubtitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface CardMessageItemTitle {
  __typename?: 'CardMessageItemTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface CardReviewContentItem {
  __typename?: 'CardReviewContentItem';
  title: CardReviewContentItemTitle;
  subtitle?: Maybe<CardReviewContentItemSubitle>;
  items: Array<CardReviewContentItemBodyItem>;
  image?: Maybe<CardReviewContentItemImage>;
  audio?: Maybe<CardReviewContentItemAudio>;
}

export interface CardReviewContentItemAudio {
  __typename?: 'CardReviewContentItemAudio';
  url: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
}

export interface CardReviewContentItemBodyImage {
  __typename?: 'CardReviewContentItemBodyImage';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export type CardReviewContentItemBodyItem =
  | CardReviewContentItemBodyImage
  | CardReviewContentItemBodyText;

export interface CardReviewContentItemBodyText {
  __typename?: 'CardReviewContentItemBodyText';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface CardReviewContentItemImage {
  __typename?: 'CardReviewContentItemImage';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export interface CardReviewContentItemSubitle {
  __typename?: 'CardReviewContentItemSubitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface CardReviewContentItemTitle {
  __typename?: 'CardReviewContentItemTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export enum CefrLevel {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
}

export interface ChangeCourseInput {
  courseId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ChangeCoursePayload {
  __typename?: 'ChangeCoursePayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ChangeLevelOptionInput {
  levelOption: LevelOption;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ChangeLevelOptionPayload {
  __typename?: 'ChangeLevelOptionPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

/** A chat that will be displayed in the users list of chats */
export interface Chat {
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
}

/** A representation of a chat message between peers */
export interface ChatMessage {
  __typename?: 'ChatMessage';
  /** The unique identification GUID for this message */
  id: Scalars['ID'];
  /** The id of the user who created the message */
  fromUserId: Scalars['ID'];
  /** The id of the chat the message was sent to */
  toChatId: Scalars['ID'];
  /** The message contents */
  body: Scalars['Dom'];
  /** Timestamp as ISO 8601 string */
  created: Scalars['DateTime'];
}

export interface ChatMessageSearch {
  __typename?: 'ChatMessageSearch';
  results?: Maybe<LanguageBuddyChatMessageConnection>;
}

export interface ChatMessageSearchResultsArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
}

/** The type of chat */
export enum ChatType {
  /** An assistant to help the user with tasks */
  Assistant = 'assistant',
  /** A lesson for the user to complete */
  Lesson = 'lesson',
  /** A lesson for the user to complete */
  Courseware = 'courseware',
  /** A chat to submit feedback on the app */
  Feedback = 'feedback',
  /** A chat to open feedback conversations */
  FeedbackConversations = 'feedbackConversations',
  /** A chat for the user to report problems with the app */
  BugReporting = 'bugReporting',
  /** A chat between users */
  User = 'user',
  /** A chat following a dialog in a practice section */
  Practice = 'practice',
  /** A chat showing a sample of a premium chat */
  PremiumSample = 'premiumSample',
  /** A chat the teacher chats */
  Teacher = 'teacher',
  /** A chat that allows users to study a study set */
  StudySet = 'studySet',
}

/** A user in the chat */
export interface ChatUser {
  __typename?: 'ChatUser';
  /** The user's id */
  id?: Maybe<Scalars['String']>;
  /** The name of the user */
  name?: Maybe<Scalars['String']>;
}

export interface ClassBooking extends Node {
  __typename?: 'ClassBooking';
  /** The ID of an object */
  id: Scalars['ID'];
  bookingTimeslot: BookingTimeslot;
  status: ClassBookingStatus;
  classTopic?: Maybe<ClassTopic>;
  channelIdentifier?: Maybe<Scalars['String']>;
  notes: Array<ClassBookingNote>;
  hasUnreadNotes: Scalars['Boolean'];
  user?: Maybe<User>;
  created: Scalars['DateTime'];
  teacher?: Maybe<Admin>;
}

/** A connection to a list of items. */
export interface ClassBookingConnection {
  __typename?: 'ClassBookingConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ClassBookingEdge>>>;
}

/** An edge in a connection. */
export interface ClassBookingEdge {
  __typename?: 'ClassBookingEdge';
  /** The item at the end of the edge */
  node?: Maybe<ClassBooking>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export interface ClassBookingNote extends Node {
  __typename?: 'ClassBookingNote';
  /** The ID of an object */
  id: Scalars['ID'];
  note: Scalars['String'];
}

export enum ClassBookingStatus {
  Canceled = 'CANCELED',
  Confirmed = 'CONFIRMED',
}

/** All supported fields for different classifiers */
export interface ClassifierData {
  /** Base64 encoded resource */
  base64?: Maybe<Scalars['String']>;
}

/** A known classifier able to produce a confidence score */
export enum ClassifierType {
  /** Default speech grading classifier */
  SpeechGradingClassifier = 'SpeechGradingClassifier',
  /** Classify using EFs Speech Grading engine */
  SpeechGradingClassifierEfSpeech = 'SpeechGradingClassifierEFSpeech',
  /** Classify using the Transcription Service Classifier */
  SpeechGradingClassifierTranscription = 'SpeechGradingClassifierTranscription',
}

export interface ClassTopic extends Node {
  __typename?: 'ClassTopic';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  lesson?: Maybe<CoursewareLesson>;
  deckEmbedUrl?: Maybe<Scalars['String']>;
  availableBookingTimeslots: Array<BookingTimeslot>;
}

/** The client being run by the user */
export enum Client {
  /** Apple mobile operating system */
  Ios = 'ios',
  /** Android mobile operating system */
  Android = 'android',
}

/** Information about potential updates to the client */
export interface ClientUpdate {
  __typename?: 'ClientUpdate';
  /** The type of update available to the user */
  updateType?: Maybe<UpdateType>;
  /** Optional url to download the update */
  url?: Maybe<Scalars['String']>;
}

export interface CompleteDailyCheckinInput {
  studySessionId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CompleteDailyCheckinPayload {
  __typename?: 'CompleteDailyCheckinPayload';
  viewer: Viewer;
  dailyCheckin?: Maybe<DailyCheckin>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CompleteStudySessionInput {
  studySessionId: Scalars['ID'];
  points: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CompleteStudySessionPayload {
  __typename?: 'CompleteStudySessionPayload';
  viewer: Viewer;
  studySession: StudySession;
  chanceOfSuccessIncrement?: Maybe<Scalars['Float']>;
  /** Returns a daily checkin object if a new checkin is created, and null if not */
  checkin?: Maybe<DailyCheckin>;
  reviewNote?: Maybe<ReviewNote>;
  sectionProgress?: Maybe<SectionProgress>;
  generatedStudySetChat?: Maybe<StudySetChat>;
  clientMutationId?: Maybe<Scalars['String']>;
}

/** The calculated confidence, with potential additional data */
export interface ConfidenceData {
  __typename?: 'ConfidenceData';
  /** Confidence score, a value between 0 - 1 */
  confidence: Scalars['Float'];
  /** Additional data returned by the specified classifier */
  data?: Maybe<ConfienceData>;
}

/** Additional data returned by the specified classifier */
export type ConfienceData = SpeechTranscriptionData | SpeechWordScoreData;

/** Returns information for different conversations */
export interface Conversation {
  __typename?: 'Conversation';
  /** The share conversation in courseware */
  coursewareShare?: Maybe<CoursewareShare>;
  /** The review conversation in courseware */
  coursewareReview?: Maybe<CoursewareReview>;
  /** The conversation to begin courseware */
  coursewareStart?: Maybe<CoursewareStart>;
  /** The study reminder conversation in courseware */
  coursewareStudyReminder?: Maybe<CoursewareStudyReminder>;
}

export enum CourseDifficulty {
  None = 'NONE',
  Basic = 'BASIC',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED',
}

/** A users progression inside a course */
export interface CourseProgress {
  __typename?: 'CourseProgress';
  /** The ID of an object */
  id: Scalars['ID'];
  course: CoursewareCourse;
  /** The section the user would be on if they began studying this course. It will be null if the user has completed the course. */
  currentSection?: Maybe<CoursewareStudySection>;
}

/** A type of chat that the user should interact with a bot to learn English */
export interface CoursewareChat extends Chat, Node {
  __typename?: 'CoursewareChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
  intro?: Maybe<Dialog>;
  botType: Scalars['String'];
  paywallUnlockTime?: Maybe<Scalars['DateTime']>;
}

/** A type of chat that the user should interact with a bot to learn English */
export interface CoursewareChatIntroArgs {
  isFirstRun?: Maybe<Scalars['Boolean']>;
}

/** Main courseware */
export interface CoursewareConfig {
  __typename?: 'CoursewareConfig';
  /** Version of the courseware formations */
  currentFormationsVersion: Scalars['Int'];
}

export interface CoursewareCourse extends Node {
  __typename?: 'CoursewareCourse';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  difficulty: CourseDifficulty;
  cefrLevel: CefrLevel;
  lessons: Array<CoursewareLesson>;
  /** The number of lessons in the course */
  lessonsLength: Scalars['Int'];
}

export interface CoursewareLesson extends Node {
  __typename?: 'CoursewareLesson';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  /** The number of the lesson */
  number: Scalars['Int'];
  /** The number of the lesson within its course */
  numberInCourse: Scalars['Int'];
  level: Level;
  course: CoursewareCourse;
  /** The number of sections in the lesson */
  sectionsLength: Scalars['Int'];
  sections: Array<CoursewareStudySection>;
}

/** Information for the courseware review conversation  */
export interface CoursewareReview {
  __typename?: 'CoursewareReview';
  /** The id of the first formation */
  formationStartId: Scalars['Guid'];
}

export type CoursewareReviewSummaryContentItem =
  | ReviewTopic
  | TextReviewContentItem
  | GifReviewContentItem
  | AudioReviewContentItem
  | AudioCarouselReviewContentItem
  | WebLinkReviewContentItem
  | VideoReviewContentItem
  | ImageReviewContentItem
  | CardReviewContentItem
  | CardCarouselReviewContentItem;

/** Information for the courseware share conversation  */
export interface CoursewareShare {
  __typename?: 'CoursewareShare';
  /** The id of the first formation */
  formationStartId: Scalars['Guid'];
}

/** Information for the courseware start conversation  */
export interface CoursewareStart {
  __typename?: 'CoursewareStart';
  /** The id of the first formation */
  formationStartId: Scalars['Guid'];
}

/** Information for the courseware study reminder conversation  */
export interface CoursewareStudyReminder {
  __typename?: 'CoursewareStudyReminder';
  /** The id of the first formation */
  formationStartId: Scalars['Guid'];
}

export interface CoursewareStudySection extends Node, StudySection {
  __typename?: 'CoursewareStudySection';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The number of the section */
  number: Scalars['Int'];
  dialog: Dialog;
  reviewSummaryContents: Array<CoursewareReviewSummaryContentItem>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sectionType: CoursewareStudySectionType;
  icon?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  lesson: CoursewareLesson;
  /** Vocab directly included in courseware data */
  vocab?: Maybe<Array<CoursewareVocab>>;
  /** New words appearing in this section for the first time */
  implicitVocab?: Maybe<Array<Scalars['String']>>;
}

export enum CoursewareStudySectionType {
  Grammar = 'GRAMMAR',
  Speaking = 'SPEAKING',
  Listening = 'LISTENING',
  Vocabulary = 'VOCABULARY',
  Reading = 'READING',
  Expressions = 'EXPRESSIONS',
  Quiz = 'QUIZ',
  Skills = 'SKILLS',
  FinalTask = 'FINAL_TASK',
}

export interface CoursewareVocab {
  __typename?: 'CoursewareVocab';
  /** The ID of an object */
  id: Scalars['ID'];
  word: Scalars['String'];
  translation?: Maybe<Scalars['String']>;
  definition: CoursewareVocabDefinition;
  imageUrl?: Maybe<Scalars['String']>;
  audioUrl?: Maybe<Scalars['String']>;
  phonetics?: Maybe<Scalars['String']>;
  examples?: Maybe<Array<CoursewareVocabExample>>;
}

export interface CoursewareVocabDefinition {
  __typename?: 'CoursewareVocabDefinition';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface CoursewareVocabExample {
  __typename?: 'CoursewareVocabExample';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface CreateClassBookingInput {
  bookingTimeslotToken: Scalars['String'];
  classTopicId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateClassBookingPayload {
  __typename?: 'CreateClassBookingPayload';
  viewer: Viewer;
  classBooking: ClassBooking;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateSendbirdSessionInput {
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateSendbirdSessionPayload {
  __typename?: 'CreateSendbirdSessionPayload';
  viewer: Viewer;
  token: Scalars['String'];
  expires: Scalars['DateTime'];
  sendbirdUserId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateStripeCheckoutSessionInput {
  skuId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateStripeCheckoutSessionPayload {
  __typename?: 'CreateStripeCheckoutSessionPayload';
  checkoutSession: StripeCheckoutSession;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreditBundle {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}

export interface DailyCheckin extends Node {
  __typename?: 'DailyCheckin';
  /** The ID of an object */
  id: Scalars['ID'];
  date: Scalars['Date'];
}

/** Points given to a user for various reasons */
export interface DailyPointsGift {
  __typename?: 'DailyPointsGift';
  /** The amount of points in the gift */
  points: Scalars['Int'];
  /** A token needed to redeem the gift */
  token: Scalars['String'];
}

/** The result of redeeming a gift */
export interface DailyPointsRedeemedGift {
  __typename?: 'DailyPointsRedeemedGift';
  /** The amount of points added to the user. If a gift has already been spent this will be 0. */
  pointsReceived: Scalars['Int'];
  /** The amount of points in the gift */
  giftValue: Scalars['Int'];
  /** A token needed to redeem the gift */
  token: Scalars['String'];
}

/** A delight to show the user */
export interface Delight {
  __typename?: 'Delight';
  /** A delight dom node. */
  node: Scalars['Dom'];
}

/** The kind of delight required by the client, expresses a mood */
export enum DelightKind {
  /** A success delight */
  Success = 'success',
  /** A failure delight */
  Failure = 'failure',
}

/** The type of delight the client would like to present to the user, e.g. video */
export enum DelightType {
  /** A Video */
  Video = 'video',
}

export interface Dialog {
  __typename?: 'Dialog';
  segments: Array<DialogSegment>;
}

export interface DialogBaseSegment extends DialogSegment {
  __typename?: 'DialogBaseSegment';
  statements: Array<DialogStatement>;
  conditional?: Maybe<DialogSegmentConditional>;
}

export interface DialogMultipleChoiceTextChoice {
  __typename?: 'DialogMultipleChoiceTextChoice';
  text: Scalars['String'];
}

export interface DialogMultipleChoiceTextQuestion extends DialogQuestion {
  __typename?: 'DialogMultipleChoiceTextQuestion';
  statements: Array<DialogStatement>;
  /** Number of attempts allowed for this question */
  maxAttempts?: Maybe<Scalars['Int']>;
  /** When this question is studied, send this token back to the server */
  studyToken?: Maybe<Scalars['String']>;
  choices: Array<DialogMultipleChoiceTextChoice>;
  correctChoiceIndex: Scalars['Int'];
  correctChoiceIndices: Array<Scalars['Int']>;
}

export interface DialogPaywallSegment extends DialogSegment {
  __typename?: 'DialogPaywallSegment';
  statements: Array<DialogStatement>;
  conditional?: Maybe<DialogSegmentConditional>;
}

export interface DialogQuestion {
  statements: Array<DialogStatement>;
  /** Number of attempts allowed for this question */
  maxAttempts?: Maybe<Scalars['Int']>;
  /** When this question is studied, send this token back to the server */
  studyToken?: Maybe<Scalars['String']>;
}

export interface DialogQuestionSegment extends DialogSegment {
  __typename?: 'DialogQuestionSegment';
  statements: Array<DialogStatement>;
  conditional?: Maybe<DialogSegmentConditional>;
  questions: Array<DialogQuestion>;
}

export interface DialogScrambleQuestion extends DialogQuestion {
  __typename?: 'DialogScrambleQuestion';
  statements: Array<DialogStatement>;
  /** Number of attempts allowed for this question */
  maxAttempts?: Maybe<Scalars['Int']>;
  /** When this question is studied, send this token back to the server */
  studyToken?: Maybe<Scalars['String']>;
  answer: Array<Scalars['String']>;
  options: Array<Scalars['String']>;
  separator?: Maybe<Scalars['String']>;
}

export interface DialogSegment {
  statements: Array<DialogStatement>;
  conditional?: Maybe<DialogSegmentConditional>;
}

export interface DialogSegmentConditional {
  __typename?: 'DialogSegmentConditional';
  statements: Array<DialogStatement>;
  choices: Array<DialogSegmentConditionalChoice>;
  name: Scalars['String'];
}

export enum DialogSegmentConditionalAction {
  Skip = 'SKIP',
  Play = 'PLAY',
}

export interface DialogSegmentConditionalChoice {
  __typename?: 'DialogSegmentConditionalChoice';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
  action: DialogSegmentConditionalAction;
}

export interface DialogSpeakingContent {
  __typename?: 'DialogSpeakingContent';
  text: Scalars['String'];
}

export interface DialogSpeakingQuestion extends DialogQuestion {
  __typename?: 'DialogSpeakingQuestion';
  statements: Array<DialogStatement>;
  /** Number of attempts allowed for this question */
  maxAttempts?: Maybe<Scalars['Int']>;
  /** When this question is studied, send this token back to the server */
  studyToken?: Maybe<Scalars['String']>;
  content: DialogSpeakingContent;
}

export interface DialogStatement {
  __typename?: 'DialogStatement';
  identifier: Scalars['String'];
  item: MessageItem;
  pauseChoice?: Maybe<PauseChoiceTextOption>;
}

/** Object containing localized and english definitions for the requested word, if any exists */
export interface DictionaryDefinitions {
  __typename?: 'DictionaryDefinitions';
  /** English definitions of the requested word */
  text: Array<Scalars['String']>;
  /** Localized definitions of the requested word */
  localizedText: Array<Scalars['String']>;
}

/** An object containing the sense information of a dictionary word */
export interface DictionarySense {
  __typename?: 'DictionarySense';
  /** Definitions of the requested word */
  definition: Scalars['String'];
  /** Part-of-Speech for the word */
  pos?: Maybe<Scalars['String']>;
  /** The inflections for this sense */
  inflections?: Maybe<Array<DictionarySenseInflection>>;
}

/** Inflections such as plural, past tense, etc. */
export interface DictionarySenseInflection {
  __typename?: 'DictionarySenseInflection';
  text: Scalars['String'];
  type: Scalars['String'];
}

export interface DictionaryWord {
  __typename?: 'DictionaryWord';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The root word for the dictionary result. This might be a different form (inflection) of the requested word. */
  word: Scalars['String'];
  /** Localized and English definitions for the requested word */
  definitions: DictionaryDefinitions;
  /** List of senses for this word */
  senses: Array<DictionarySense>;
  /** List of senses for this word, with the definition being localized */
  localizedSenses: Array<DictionarySense>;
  /** The url for the audio for the requested word */
  audioUrl?: Maybe<Scalars['String']>;
  /** The pronunciation for the requested word */
  pronunciation?: Maybe<Scalars['String']>;
  isBookmarked: Scalars['Boolean'];
}

/** Collection of dynamically changing values, for example during A/B testing */
export interface DynamicContent {
  __typename?: 'DynamicContent';
  premium?: Maybe<DynamicPremiumContent>;
}

/** Dynamic values relating to premium */
export interface DynamicPremiumContent {
  __typename?: 'DynamicPremiumContent';
  headline: DynamicPremiumContentTitle;
  offerings: Array<DynamicPremiumOffering>;
  reviews: Array<DynamicPremiumReview>;
}

export interface DynamicPremiumContentTitle {
  __typename?: 'DynamicPremiumContentTitle';
  title: Scalars['String'];
  subtitle: Scalars['String'];
}

/** A premium offering list item */
export interface DynamicPremiumOffering {
  __typename?: 'DynamicPremiumOffering';
  title: Scalars['String'];
  subtitle: Scalars['String'];
}

/** A premium review */
export interface DynamicPremiumReview {
  __typename?: 'DynamicPremiumReview';
  content: Scalars['String'];
  author: Scalars['String'];
}

/** Key value pair of key and translated text */
export interface DynamicString {
  __typename?: 'DynamicString';
  key: Scalars['String'];
  text: Scalars['String'];
}

/** Collection of all dynamic strings as key value pairs */
export interface DynamicStrings {
  __typename?: 'DynamicStrings';
  items: Array<DynamicString>;
}

/** The status returned from the assessment engine */
export enum EstimationStatus {
  /** A successful request */
  Success = 'success',
  /** A general unsuccessful request */
  Failure = 'failure',
  /** An unsuccessful request. The users' responses were too short for the assessment engine to determine a level */
  TooShort = 'tooShort',
  /** An unsuccessful request. The users' responses were not understood the assessment engine */
  Unintelligible = 'unintelligible',
}

/** A chat for the user to submit feedback on the app */
export interface FeedbackChat extends Chat, Node {
  __typename?: 'FeedbackChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
}

/** A chat for the user to view their feedback conversations */
export interface FeedbackConversationsChat extends Chat, Node {
  __typename?: 'FeedbackConversationsChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
}

export interface FindLanguageBuddyInput {
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface FindLanguageBuddyPayload {
  __typename?: 'FindLanguageBuddyPayload';
  userChat?: Maybe<UserChat>;
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

/** The `Formation` object type represents [EF Carbon Conversation](https://github.com/ef-carbon/primitive) Formation. */
export interface FormationDom {
  __typename?: 'FormationDom';
  /** The unique identification number of the formation */
  id: Scalars['Guid'];
  /** The interaction to perform with the user for this formation */
  discourse: Scalars['DiscourseDom'];
  /** The link to the next formation to process */
  link: Scalars['Link'];
  /** Tags that are applied to this formation to give it context */
  tags?: Maybe<Array<Scalars['String']>>;
  /** Meta information attached to the formation */
  meta?: Maybe<Scalars['FormationMeta']>;
}

/** A single edge in a formations connection */
export interface FormationDomEdge {
  __typename?: 'FormationDomEdge';
  /** The cursor into the connection that can be used to perform later pagination queries */
  cursor: Scalars['Guid'];
  /** The formation data for the edge */
  node: FormationDom;
}

/** The formation connection can provide pagination of formations */
export interface FormationFormationDomConnection {
  __typename?: 'FormationFormationDomConnection';
  /** The edges for the connections request */
  edges: Array<FormationDomEdge>;
  /** Determines if there is another page after the currently requested page */
  pageInfo: FormationPageInfo;
}

/** Determies the current page metadata */
export interface FormationPageInfo {
  __typename?: 'FormationPageInfo';
  /** The starting GUID for the tree */
  startCursor: Scalars['Guid'];
  /** Determines if there are more pages of conversation formations after this page */
  hasNextPage: Scalars['Boolean'];
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Na = 'NA',
}

export interface GenerateCurrentCoursewareStudySectionInput {
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GenerateCurrentCoursewareStudySectionPayload {
  __typename?: 'GenerateCurrentCoursewareStudySectionPayload';
  viewer: Viewer;
  studySection?: Maybe<CoursewareStudySection>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GeneratePracticeChatStudySectionInput {
  practiceChatId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GeneratePracticeChatStudySectionPayload {
  __typename?: 'GeneratePracticeChatStudySectionPayload';
  studySection?: Maybe<BaseStudySection>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GenerateStudySetChatStudySectionInput {
  studySetId?: Maybe<Scalars['ID']>;
  sectionType: StudySetSectionType;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GenerateStudySetChatStudySectionPayload {
  __typename?: 'GenerateStudySetChatStudySectionPayload';
  viewer: Viewer;
  studySection?: Maybe<BaseStudySection>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GifMessageItem {
  __typename?: 'GifMessageItem';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export interface GifReviewContentItem {
  __typename?: 'GifReviewContentItem';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

/** Global configuration values */
export interface GlobalConfig {
  __typename?: 'GlobalConfig';
  /** Information about the backend */
  backendInfo: BackendInfo;
  /** Study Reminder feature */
  studyReminder?: Maybe<StudyReminderConfig>;
  /** Courseware */
  courseware?: Maybe<CoursewareConfig>;
}

export interface GoogleIap {
  __typename?: 'GoogleIAP';
  premiumPricePlans: Array<PremiumPricePlanGooglePlay>;
}

/** An object with speech grading results for a given word  */
export interface GradeSpeechPhonemeGrade {
  __typename?: 'GradeSpeechPhonemeGrade';
  letters: Scalars['String'];
  score: Scalars['Float'];
  isCorrect: Scalars['Boolean'];
}

/** The calculated confidence, with potential additional data */
export interface GradeSpeechResult {
  __typename?: 'GradeSpeechResult';
  isCorrect: Scalars['Boolean'];
  /** score from 0 - 1 indicating how well the student did */
  score: Scalars['Float'];
  wordGrades?: Maybe<Array<GradeSpeechWordGrade>>;
}

/** An object with speech grading results for a given word  */
export interface GradeSpeechWordGrade {
  __typename?: 'GradeSpeechWordGrade';
  word: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  score: Scalars['Float'];
  phonemeGrades?: Maybe<Array<GradeSpeechPhonemeGrade>>;
}

export interface HomescreenActionButton {
  __typename?: 'HomescreenActionButton';
  text: Scalars['String'];
  url: Scalars['String'];
}

export interface ImageMessageItem {
  __typename?: 'ImageMessageItem';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export interface ImageReviewContentItem {
  __typename?: 'ImageReviewContentItem';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export interface JoinVideoClassroomInput {
  classBookingId: Scalars['ID'];
  videoStream?: Maybe<JoinVideoClassroomVideoStream>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface JoinVideoClassroomPayload {
  __typename?: 'JoinVideoClassroomPayload';
  token: Scalars['String'];
  account: Scalars['String'];
  channel: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export enum JoinVideoClassroomVideoStream {
  Camera = 'CAMERA',
  Screen = 'SCREEN',
  None = 'NONE',
}

export interface LanguageBuddyChat extends Node {
  __typename?: 'LanguageBuddyChat';
  /** The ID of an object */
  id: Scalars['ID'];
  users: Array<User>;
  messages: Array<LanguageBuddyChatMessage>;
  isReported: Scalars['Boolean'];
  created: Scalars['DateTime'];
}

export interface LanguageBuddyChatMessage {
  __typename?: 'LanguageBuddyChatMessage';
  /** The ID of an object */
  id: Scalars['ID'];
  languageBuddyChat?: Maybe<LanguageBuddyChat>;
  user?: Maybe<User>;
  content: Scalars['String'];
  created: Scalars['DateTime'];
}

/** A connection to a list of items. */
export interface LanguageBuddyChatMessageConnection {
  __typename?: 'LanguageBuddyChatMessageConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LanguageBuddyChatMessageEdge>>>;
}

/** An edge in a connection. */
export interface LanguageBuddyChatMessageEdge {
  __typename?: 'LanguageBuddyChatMessageEdge';
  /** The item at the end of the edge */
  node?: Maybe<LanguageBuddyChatMessage>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** A type of chat that the user should interact with a bot to learn English */
export interface LessonChat extends Chat, Node {
  __typename?: 'LessonChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
  /** The id of the current lesson */
  lessonId?: Maybe<Scalars['String']>;
}

/** A users progression through the lesson */
export interface LessonProgress {
  __typename?: 'LessonProgress';
  /** The ID of an object */
  id: Scalars['ID'];
  lesson: CoursewareLesson;
  /** The user ID the progress is for */
  userId?: Maybe<Scalars['String']>;
  /** The ID of the lesson */
  lessonId?: Maybe<Scalars['String']>;
  /** The title of the lesson */
  lessonTitle?: Maybe<Scalars['String']>;
  /** The current lesson ID */
  isCompleted: Scalars['Boolean'];
  /** When the lesson was started */
  startDate?: Maybe<Scalars['DateTime']>;
  /** When the lesson was completed */
  completedDate?: Maybe<Scalars['DateTime']>;
  /** The progression through the lesson sections */
  sectionsProgress?: Maybe<Array<SectionProgress>>;
  /** The points that the user has accumulated */
  points?: Maybe<Scalars['Int']>;
  /** The location of this lesson in the level lessons */
  levelProgress: LevelProgress;
  sectionTypeStats?: Maybe<Array<SectionTypeStat>>;
}

/** A connection to a list of items. */
export interface LessonProgressConnection {
  __typename?: 'LessonProgressConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LessonProgressEdge>>>;
}

/** An edge in a connection. */
export interface LessonProgressEdge {
  __typename?: 'LessonProgressEdge';
  /** The item at the end of the edge */
  node?: Maybe<LessonProgress>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export interface Level extends Node {
  __typename?: 'Level';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The number of the level */
  number: Scalars['Int'];
  /** Lesson contents */
  description?: Maybe<Scalars['String']>;
  /** Level of difficulty */
  difficulty: Scalars['String'];
  /** The number of lessons in the level */
  lessonsLength: Scalars['Int'];
  lessons: Array<CoursewareLesson>;
}

export enum LevelOption {
  None = 'NONE',
  Basic = 'BASIC',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED',
}

export interface LevelOptionInformation {
  __typename?: 'LevelOptionInformation';
  levelOption: LevelOption;
  /** The starting level for this level option */
  fromLevelNumber: Scalars['Int'];
  /** The ending level for this level option */
  toLevelNumber: Scalars['Int'];
  /** The number of lessons in this level option */
  totalLessons?: Maybe<Scalars['Int']>;
  /** The level the user was last active on in this level option */
  lastActiveLevelNumber?: Maybe<Scalars['Int']>;
  /** The last active study section in this level option, or null if none */
  lastActiveLessonOffset?: Maybe<Scalars['Int']>;
  /** If this level option is joinable */
  isCompleted: Scalars['Boolean'];
}

/** A users progression inside a level */
export interface LevelProgress {
  __typename?: 'LevelProgress';
  /** The ID of an object */
  id: Scalars['ID'];
  level: Level;
  /** The index of lessons in the level this lesson is at */
  idx?: Maybe<Scalars['Int']>;
  /** The number of lessons in the level that this lesson is in */
  length?: Maybe<Scalars['Int']>;
}

export interface LockInBucketAssignmentInput {
  bucketGroup: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface LockInBucketAssignmentPayload {
  __typename?: 'LockInBucketAssignmentPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface MarkClassBookingNotesReadInput {
  classBookingId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface MarkClassBookingNotesReadPayload {
  __typename?: 'MarkClassBookingNotesReadPayload';
  viewer: Viewer;
  classBooking?: Maybe<ClassBooking>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export type MessageItem =
  | TextMessageItem
  | GifMessageItem
  | AudioMessageItem
  | AudioCarouselMessageItem
  | VideoMessageItem
  | WebLinkMessageItem
  | ImageMessageItem
  | CardMessageItem
  | CardCarouselMessageItem;

export interface Mutation {
  __typename?: 'Mutation';
  /** Redeem a gift */
  redeemGift?: Maybe<RedeemGiftPayload>;
  /** Submit an end of section progression update */
  sectionComplete?: Maybe<LessonProgress>;
  /** Update current progress for a user */
  updateCurrentProgressLevel?: Maybe<Progress>;
  /** Update current progress for a user */
  updateCurrentProgressLesson?: Maybe<Progress>;
  /** Update current level for a user based on a level option */
  updateCurrentProgressLevelOption?: Maybe<Progress>;
  /** Report a chat */
  reportUserChat?: Maybe<UserToUserChat>;
  findLanguageBuddy?: Maybe<FindLanguageBuddyPayload>;
  /** Send a message to a chat */
  sendMessage?: Maybe<ChatMessage>;
  /** Mark a message as read */
  messageRead?: Maybe<Scalars['Boolean']>;
  /** Assessment endpoint that returns a user level */
  submitAssessment?: Maybe<AssessmentResult>;
  completeDailyCheckin?: Maybe<CompleteDailyCheckinPayload>;
  submitDailyCheckin?: Maybe<SubmitDailyCheckinPayload>;
  registerAccount?: Maybe<RegisterAccountPayload>;
  registerAccountAnonymous?: Maybe<RegisterAccountAnonymousPayload>;
  updateAccountInfo?: Maybe<UpdateAccountInfoPayload>;
  recordStudyResponse?: Maybe<RecordStudyResponsePayload>;
  completeStudySession?: Maybe<CompleteStudySessionPayload>;
  startStudySession?: Maybe<StartStudySessionPayload>;
  generatePracticeChatStudySection?: Maybe<
    GeneratePracticeChatStudySectionPayload
  >;
  generateCurrentCoursewareStudySection?: Maybe<
    GenerateCurrentCoursewareStudySectionPayload
  >;
  validatePurchase?: Maybe<ValidatePurchasePayload>;
  validateGooglePurchase?: Maybe<ValidateGooglePurchasePayload>;
  lockInBucketAssignment?: Maybe<LockInBucketAssignmentPayload>;
  /** Redeem a voucher code */
  redeemVoucher?: Maybe<RedeemVoucherPayload>;
  updateAnnotationForReviewNote?: Maybe<UpdateAnnotationForReviewNotePayload>;
  updateCurrentProgressSection?: Maybe<UpdateCurrentProgressSectionPayload>;
  createClassBooking?: Maybe<CreateClassBookingPayload>;
  joinVideoClassroom?: Maybe<JoinVideoClassroomPayload>;
  submitClassReview?: Maybe<SubmitClassReviewPayload>;
  createStripeCheckoutSession?: Maybe<CreateStripeCheckoutSessionPayload>;
  markClassBookingNotesRead?: Maybe<MarkClassBookingNotesReadPayload>;
  bookmarkWord?: Maybe<BookmarkWordPayload>;
  submitTeacherQuestion?: Maybe<SubmitTeacherQuestionPayload>;
  createSendbirdSession?: Maybe<CreateSendbirdSessionPayload>;
  changeLevelOption?: Maybe<ChangeLevelOptionPayload>;
  changeCourse?: Maybe<ChangeCoursePayload>;
  generateStudySetChatStudySection?: Maybe<
    GenerateStudySetChatStudySectionPayload
  >;
  /** Redeem a promo code */
  redeemPromoCode?: Maybe<RedeemPromoCodePayload>;
  adminUpgradeUser?: Maybe<AdminUpgradeUserPayload>;
  adminAddCredits?: Maybe<AdminAddCreditsPayload>;
  adminCreateClassBookingNote?: Maybe<AdminCreateClassBookingNotePayload>;
  adminCancelClassBooking?: Maybe<AdminCancelClassBookingPayload>;
  adminUpdateAdmin?: Maybe<AdminUpdateAdminPayload>;
  adminUpdateUserLevel?: Maybe<AdminUpdateUserLevelPayload>;
  adminCreatePromoCode?: Maybe<AdminCreatePromoCodePayload>;
  adminRedeemPromoCodeWithUserId?: Maybe<AdminRedeemPromoCodeWithUserIdPayload>;
  adminRedeemPromoCodeWithPhoneNumber?: Maybe<
    AdminRedeemPromoCodeWithPhoneNumberPayload
  >;
  adminUpdatePromoCode?: Maybe<AdminUpdatePromoCodePayload>;
}

export interface MutationRedeemGiftArgs {
  input: RedeemGiftInput;
}

export interface MutationSectionCompleteArgs {
  lessonId: Scalars['String'];
  sectionId: Scalars['String'];
  points: Scalars['Int'];
}

export interface MutationUpdateCurrentProgressLevelArgs {
  levelId: Scalars['String'];
}

export interface MutationUpdateCurrentProgressLessonArgs {
  lessonId: Scalars['String'];
}

export interface MutationUpdateCurrentProgressLevelOptionArgs {
  option: Scalars['String'];
}

export interface MutationReportUserChatArgs {
  chatId: Scalars['ID'];
}

export interface MutationFindLanguageBuddyArgs {
  input: FindLanguageBuddyInput;
}

export interface MutationSendMessageArgs {
  chatId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
}

export interface MutationMessageReadArgs {
  messageId: Scalars['ID'];
  chatId: Scalars['ID'];
}

export interface MutationSubmitAssessmentArgs {
  assessmentResponse: AssessmentResponse;
}

export interface MutationCompleteDailyCheckinArgs {
  input: CompleteDailyCheckinInput;
}

export interface MutationSubmitDailyCheckinArgs {
  input: SubmitDailyCheckinInput;
}

export interface MutationRegisterAccountArgs {
  input: RegisterAccountInput;
}

export interface MutationRegisterAccountAnonymousArgs {
  input: RegisterAccountAnonymousInput;
}

export interface MutationUpdateAccountInfoArgs {
  input: UpdateAccountInfoInput;
}

export interface MutationRecordStudyResponseArgs {
  input: RecordStudyResponseInput;
}

export interface MutationCompleteStudySessionArgs {
  input: CompleteStudySessionInput;
}

export interface MutationStartStudySessionArgs {
  input: StartStudySessionInput;
}

export interface MutationGeneratePracticeChatStudySectionArgs {
  input: GeneratePracticeChatStudySectionInput;
}

export interface MutationGenerateCurrentCoursewareStudySectionArgs {
  input: GenerateCurrentCoursewareStudySectionInput;
}

export interface MutationValidatePurchaseArgs {
  input: ValidatePurchaseInput;
}

export interface MutationValidateGooglePurchaseArgs {
  input: ValidateGooglePurchaseInput;
}

export interface MutationLockInBucketAssignmentArgs {
  input: LockInBucketAssignmentInput;
}

export interface MutationRedeemVoucherArgs {
  input: RedeemVoucherInput;
}

export interface MutationUpdateAnnotationForReviewNoteArgs {
  input: UpdateAnnotationForReviewNoteInput;
}

export interface MutationUpdateCurrentProgressSectionArgs {
  input: UpdateCurrentProgressSectionInput;
}

export interface MutationCreateClassBookingArgs {
  input: CreateClassBookingInput;
}

export interface MutationJoinVideoClassroomArgs {
  input: JoinVideoClassroomInput;
}

export interface MutationSubmitClassReviewArgs {
  input: SubmitClassReviewInput;
}

export interface MutationCreateStripeCheckoutSessionArgs {
  input: CreateStripeCheckoutSessionInput;
}

export interface MutationMarkClassBookingNotesReadArgs {
  input: MarkClassBookingNotesReadInput;
}

export interface MutationBookmarkWordArgs {
  input: BookmarkWordInput;
}

export interface MutationSubmitTeacherQuestionArgs {
  input: SubmitTeacherQuestionInput;
}

export interface MutationCreateSendbirdSessionArgs {
  input: CreateSendbirdSessionInput;
}

export interface MutationChangeLevelOptionArgs {
  input: ChangeLevelOptionInput;
}

export interface MutationChangeCourseArgs {
  input: ChangeCourseInput;
}

export interface MutationGenerateStudySetChatStudySectionArgs {
  input: GenerateStudySetChatStudySectionInput;
}

export interface MutationRedeemPromoCodeArgs {
  input: RedeemPromoCodeInput;
}

export interface MutationAdminUpgradeUserArgs {
  input: AdminUpgradeUserInput;
}

export interface MutationAdminAddCreditsArgs {
  input: AdminAddCreditsInput;
}

export interface MutationAdminCreateClassBookingNoteArgs {
  input: AdminCreateClassBookingNoteInput;
}

export interface MutationAdminCancelClassBookingArgs {
  input: AdminCancelClassBookingInput;
}

export interface MutationAdminUpdateAdminArgs {
  input: AdminUpdateAdminInput;
}

export interface MutationAdminUpdateUserLevelArgs {
  input: AdminUpdateUserLevelInput;
}

export interface MutationAdminCreatePromoCodeArgs {
  input: AdminCreatePromoCodeInput;
}

export interface MutationAdminRedeemPromoCodeWithUserIdArgs {
  input: AdminRedeemPromoCodeWithUserIdInput;
}

export interface MutationAdminRedeemPromoCodeWithPhoneNumberArgs {
  input: AdminRedeemPromoCodeWithPhoneNumberInput;
}

export interface MutationAdminUpdatePromoCodeArgs {
  input: AdminUpdatePromoCodeInput;
}

/** An object with an ID */
export interface Node {
  /** The id of the object. */
  id: Scalars['ID'];
}

/** Information about pagination in a connection. */
export interface PageInfo {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
}

export interface PauseChoiceTextOption {
  __typename?: 'PauseChoiceTextOption';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface PaymentProvider {
  __typename?: 'PaymentProvider';
  appleIAP?: Maybe<AppleIap>;
  googleIAP?: Maybe<GoogleIap>;
  voucherIAP?: Maybe<VoucherIap>;
  stripeCheckout?: Maybe<StripeCheckoutPaymentProvider>;
}

/** Points balance for a given date */
export interface PointBalance {
  __typename?: 'PointBalance';
  /** Total points this day */
  total: Scalars['Float'];
  /** Calendar day formated as YYYY-MM-DD */
  date: Scalars['Date'];
}

export interface PracticeChat extends Chat, Node {
  __typename?: 'PracticeChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
  intro?: Maybe<Dialog>;
  sectionEndQuestion?: Maybe<TextMessageItem>;
  botType: Scalars['String'];
}

export interface PracticeChatIntroArgs {
  isFirstRun?: Maybe<Scalars['Boolean']>;
}

export interface PracticeChatSectionEndQuestionArgs {
  isFirstRun?: Maybe<Scalars['Boolean']>;
}

export interface Premium {
  __typename?: 'Premium';
  isEnabled: Scalars['Boolean'];
  planType?: Maybe<PremiumPlanType>;
  endTime?: Maybe<Scalars['DateTime']>;
}

export enum PremiumPlanType {
  Plus = 'Plus',
  PlusTeacher = 'PlusTeacher',
}

export interface PremiumPricePlan {
  type: PremiumPlanType;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  credits: Scalars['Int'];
}

export interface PremiumPricePlanAppStore extends PremiumPricePlan {
  __typename?: 'PremiumPricePlanAppStore';
  type: PremiumPlanType;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  credits: Scalars['Int'];
  appStoreSkuId: Scalars['String'];
  discount?: Maybe<PremiumPricePlanAppStoreDiscount>;
}

export interface PremiumPricePlanAppStoreDiscount {
  __typename?: 'PremiumPricePlanAppStoreDiscount';
  details: PremiumPricePlanAppStoreDiscountOffer;
}

export interface PremiumPricePlanAppStoreDiscountOffer {
  __typename?: 'PremiumPricePlanAppStoreDiscountOffer';
  keyId: Scalars['String'];
  productId: Scalars['String'];
  offerId: Scalars['String'];
  applicationUsername: Scalars['String'];
  nonce: Scalars['String'];
  timestamp: Scalars['DateTime'];
  signature: Scalars['String'];
}

export interface PremiumPricePlanGooglePlay extends PremiumPricePlan {
  __typename?: 'PremiumPricePlanGooglePlay';
  type: PremiumPlanType;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  credits: Scalars['Int'];
  skuId: Scalars['String'];
}

export interface PremiumPricePlanVoucher extends PremiumPricePlan {
  __typename?: 'PremiumPricePlanVoucher';
  type: PremiumPlanType;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  credits: Scalars['Int'];
  voucherCode: Scalars['String'];
}

/** A chat showing a sample of a premium chat */
export interface PremiumSampleChat extends Chat, Node {
  __typename?: 'PremiumSampleChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
}

/** A users progress information within courseware */
export interface Progress {
  __typename?: 'Progress';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The user ID the progress is for */
  userId?: Maybe<Scalars['String']>;
  /** The current section progress */
  sectionProgress?: Maybe<SectionProgress>;
  /** The current lesson ID */
  currentLessonId?: Maybe<Scalars['String']>;
  /** The current level ID */
  currentLevelId?: Maybe<Scalars['String']>;
  /** The current level number */
  currentLevelNumber?: Maybe<Scalars['Int']>;
  /** The points that the user has accumulated */
  points?: Maybe<Scalars['Int']>;
  currentLevelOptionInformation: LevelOptionInformation;
  currentCourseProgress: CourseProgress;
  allCourseProgresses: Array<CourseProgress>;
  /** The current chance of success for the user, between 0 and 1 */
  currentChanceOfSuccess?: Maybe<Scalars['Float']>;
  /** The increase in chance of success the user will gain on completing a section */
  chanceOfSuccessPotentialGain?: Maybe<Scalars['Float']>;
  lessonProgressHistory?: Maybe<LessonProgressConnection>;
}

/** A users progress information within courseware */
export interface ProgressLessonProgressHistoryArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}

export interface PromoCode {
  __typename?: 'PromoCode';
  /** The ID of an object */
  id: Scalars['ID'];
  code: Scalars['String'];
  maxRedemptions?: Maybe<Scalars['Int']>;
  redemptionsRemaining?: Maybe<Scalars['Int']>;
  created: Scalars['DateTime'];
  product: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  status: PromoCodeStatus;
  orderNumber?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
}

/** A connection to a list of items. */
export interface PromoCodeConnection {
  __typename?: 'PromoCodeConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PromoCodeEdge>>>;
}

/** An edge in a connection. */
export interface PromoCodeEdge {
  __typename?: 'PromoCodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PromoCode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export enum PromoCodeProduct {
  FiveMinutes = 'FIVE_MINUTES',
  OneHour = 'ONE_HOUR',
  TwelveHours = 'TWELVE_HOURS',
  SevenDays = 'SEVEN_DAYS',
  OneMonth = 'ONE_MONTH',
  ThreeMonths = 'THREE_MONTHS',
  OneYear = 'ONE_YEAR',
  Lifetime = 'LIFETIME',
  OneHourTeacher = 'ONE_HOUR_TEACHER',
  OneYearTeacher = 'ONE_YEAR_TEACHER',
  OneYearTeacher_25Credits = 'ONE_YEAR_TEACHER_25_CREDITS',
  ThreeCreditsBundle = 'THREE_CREDITS_BUNDLE',
}

export enum PromoCodeSourceKind {
  Youzan = 'YOUZAN',
  Admin = 'ADMIN',
}

export enum PromoCodeStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Refunded = 'REFUNDED',
}

export interface Query {
  __typename?: 'Query';
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  viewer: Viewer;
  formationsConnection: FormationFormationDomConnection;
  /** Information about whether the client needs to be updated */
  clientUpdate?: Maybe<ClientUpdate>;
  /** List of the available levels */
  levels?: Maybe<Array<Maybe<Level>>>;
  /** List of the available courses */
  courses?: Maybe<Array<Maybe<CoursewareCourse>>>;
  /** The list of chats available to the user */
  chats?: Maybe<Array<Maybe<Chat>>>;
  /** A user chat item */
  userChat?: Maybe<UserChat>;
  /** Current progress for a user */
  currentProgress?: Maybe<Progress>;
  /** Messages for a chat */
  Messages?: Maybe<Array<Maybe<ChatMessage>>>;
  /** Returns a single user chat object */
  getUserChat?: Maybe<UserToUserChat>;
  /** Returns the information required to start an assessment */
  assessmentStartInfo?: Maybe<AssessmentStartInfo>;
  gradeSpeech: GradeSpeechResult;
  /** Returns information about different conversations */
  conversation?: Maybe<Conversation>;
  /** Returns a delight */
  delight?: Maybe<Delight>;
  /** Returns a list of delights */
  delights: Array<Maybe<Delight>>;
  /** A confidence level for an expected input, using a provided classifier */
  confidence: ConfidenceData;
  /** Historic points balance for a user grouped by a time interval (daily) */
  pointBalanceByInterval: Array<Maybe<PointBalance>>;
  /** Get global config information */
  globalConfig?: Maybe<GlobalConfig>;
  /** Returns data for the study reminder feature */
  studyReminder?: Maybe<StudyReminder>;
  /** Translate a word from english to local language */
  translate?: Maybe<Translation>;
  admin?: Maybe<AdminQuery>;
  wordPronunciations: Array<Maybe<WordPronunciation>>;
}

export interface QueryNodeArgs {
  id: Scalars['ID'];
}

export interface QueryFormationsConnectionArgs {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Guid']>;
  skip?: Maybe<Array<Scalars['Guid']>>;
  end?: Maybe<Scalars['Guid']>;
}

export interface QueryClientUpdateArgs {
  version?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
}

export interface QueryChatsArgs {
  userId?: Maybe<Scalars['String']>;
}

export interface QueryUserChatArgs {
  chatId?: Maybe<Scalars['String']>;
}

export interface QueryMessagesArgs {
  chatId: Scalars['ID'];
}

export interface QueryGetUserChatArgs {
  chatId?: Maybe<Scalars['ID']>;
}

export interface QueryGradeSpeechArgs {
  audio: Base64Upload;
  expected: Scalars['String'];
}

export interface QueryDelightArgs {
  kind: DelightKind;
  type?: Maybe<DelightType>;
}

export interface QueryDelightsArgs {
  kind: DelightKind;
  type?: Maybe<DelightType>;
  amount?: Maybe<Scalars['Int']>;
}

export interface QueryConfidenceArgs {
  data: ClassifierData;
  classifier: ClassifierType;
  expected: Scalars['String'];
}

export interface QueryPointBalanceByIntervalArgs {
  fromDate: Scalars['String'];
}

export interface QueryTranslateArgs {
  word?: Maybe<Scalars['String']>;
}

export interface QueryWordPronunciationsArgs {
  words: Array<WordPronunciationArg>;
}

/** A recommendation for what the user should study next */
export interface Recommendation {
  __typename?: 'Recommendation';
  /** title of the recommendation */
  title: Scalars['String'];
  /** Decorator text to be shown with the recommendation */
  header?: Maybe<Scalars['String']>;
  /** possible url to navigate to */
  url?: Maybe<Scalars['String']>;
  /** type of the recommendation */
  kind: RecommendationKind;
  /** shows whether the recommendation is a premium feature */
  isPremium: Scalars['Boolean'];
  /** a description to be displayed along side the recommendation */
  description?: Maybe<Scalars['String']>;
}

/** Kind of recommendation */
export enum RecommendationKind {
  /** Vocabulary bot */
  VocabularyPractice = 'VocabularyPractice',
  /** Pronunciation bot */
  PronunciationPractice = 'PronunciationPractice',
  /** Listening bot */
  ListeningPractice = 'ListeningPractice',
  /** Courseware */
  Courseware = 'Courseware',
  /** Review courseware */
  ReviewPractice = 'ReviewPractice',
  /** Study break */
  StudyBreak = 'StudyBreak',
}

export interface RecordStudyResponseInput {
  studyToken: Scalars['String'];
  studySessionId: Scalars['ID'];
  isCorrect: Scalars['Boolean'];
  /** The attempt number of the learner answering this quetion, 0-indexed */
  attemptNum?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Float']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RecordStudyResponsePayload {
  __typename?: 'RecordStudyResponsePayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RedeemGiftInput {
  /** token for the gift to redeem */
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RedeemGiftPayload {
  __typename?: 'RedeemGiftPayload';
  gift?: Maybe<DailyPointsRedeemedGift>;
  /** Current progress for a user */
  currentProgress?: Maybe<Progress>;
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RedeemPromoCodeInput {
  /** promo code to redeem */
  code: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RedeemPromoCodePayload {
  __typename?: 'RedeemPromoCodePayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RedeemVoucherInput {
  /** voucher code to redeem */
  code: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RedeemVoucherPayload {
  __typename?: 'RedeemVoucherPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RegisterAccountAnonymousInput {
  levelOption?: Maybe<LevelOption>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RegisterAccountAnonymousPayload {
  __typename?: 'RegisterAccountAnonymousPayload';
  viewer: Viewer;
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RegisterAccountInput {
  phoneNumber: Scalars['String'];
  countryCode: Scalars['String'];
  password: Scalars['String'];
  verificationToken: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RegisterAccountPayload {
  __typename?: 'RegisterAccountPayload';
  viewer: Viewer;
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

/** A response to an assessment question */
export interface Response {
  __typename?: 'Response';
  /** The id of the question */
  questionId: Scalars['String'];
  /** The users response to the question */
  response: Scalars['String'];
}

/** A response to an assessment question */
export interface ResponseInput {
  /** The id of the question */
  questionId: Scalars['String'];
  /** The users response to the question */
  response: Scalars['String'];
}

export interface ReviewAnnotation {
  __typename?: 'ReviewAnnotation';
  content: Scalars['String'];
}

export type ReviewContentItem =
  | TextReviewContentItem
  | GifReviewContentItem
  | AudioReviewContentItem
  | AudioCarouselReviewContentItem
  | WebLinkReviewContentItem
  | VideoReviewContentItem
  | ImageReviewContentItem
  | CardReviewContentItem
  | CardCarouselReviewContentItem;

export interface ReviewExample {
  __typename?: 'ReviewExample';
  exampleTitle?: Maybe<ReviewExampleTitle>;
  items: Array<ReviewContentItem>;
}

export interface ReviewExampleTitle {
  __typename?: 'ReviewExampleTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface ReviewLesson extends Node {
  __typename?: 'ReviewLesson';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
  notes: Array<ReviewNote>;
  notebook: ReviewNotebook;
}

export interface ReviewNote extends Node {
  __typename?: 'ReviewNote';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  annotation?: Maybe<ReviewAnnotation>;
  stats?: Maybe<ReviewNoteStats>;
  contents: Array<ReviewNoteContentItem>;
  description: Scalars['String'];
  lesson: ReviewLesson;
  practiceStudySection?: Maybe<ReviewNotePracticeStudySection>;
}

export interface ReviewNotebook extends Node {
  __typename?: 'ReviewNotebook';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  lessons: Array<ReviewLesson>;
  lastModified: Scalars['DateTime'];
  coverNumber: Scalars['Int'];
  coverLabel: Scalars['String'];
}

export type ReviewNoteContentItem =
  | ReviewTopic
  | TextReviewContentItem
  | GifReviewContentItem
  | AudioReviewContentItem
  | AudioCarouselReviewContentItem
  | WebLinkReviewContentItem
  | VideoReviewContentItem
  | ImageReviewContentItem
  | CardReviewContentItem
  | CardCarouselReviewContentItem;

export interface ReviewNotePracticeStudySection extends Node, StudySection {
  __typename?: 'ReviewNotePracticeStudySection';
  /** The ID of an object */
  id: Scalars['ID'];
  dialog: Dialog;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  token: Scalars['String'];
}

export interface ReviewNoteStats {
  __typename?: 'ReviewNoteStats';
  bestScore: ReviewNoteStatsScore;
  previousScore?: Maybe<ReviewNoteStatsScore>;
}

export interface ReviewNoteStatsScore {
  __typename?: 'ReviewNoteStatsScore';
  score: Scalars['Int'];
  time: Scalars['DateTime'];
}

export interface ReviewTopic {
  __typename?: 'ReviewTopic';
  topicTitle?: Maybe<ReviewTopicTitle>;
  body: Array<ReviewTopicBodyItem>;
}

export type ReviewTopicBodyItem =
  | ReviewExample
  | TextReviewContentItem
  | GifReviewContentItem
  | AudioReviewContentItem
  | AudioCarouselReviewContentItem
  | WebLinkReviewContentItem
  | VideoReviewContentItem
  | ImageReviewContentItem
  | CardReviewContentItem
  | CardCarouselReviewContentItem;

export interface ReviewTopicTitle {
  __typename?: 'ReviewTopicTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

/** A users progression through the lesson */
export interface SectionProgress {
  __typename?: 'SectionProgress';
  /** The ID of an object */
  id: Scalars['ID'];
  section: CoursewareStudySection;
  /** The ID of the section */
  sectionId?: Maybe<Scalars['String']>;
  /** The title of the section */
  sectionTitle?: Maybe<Scalars['String']>;
  /** The type of the section */
  sectionType?: Maybe<SectionType>;
  /** When the section was started */
  startDate?: Maybe<Scalars['DateTime']>;
  /** When the section was completed */
  completedDate?: Maybe<Scalars['DateTime']>;
  /** The current section ID */
  isCompleted: Scalars['Boolean'];
  /** Determines if the section is currently locked */
  isLocked: Scalars['Boolean'];
  /** The ID of the conversation formation where the section starts */
  formationStartId?: Maybe<Scalars['String']>;
  /** The ID of the conversation formation where the section ends */
  formationEndId?: Maybe<Scalars['String']>;
  /** The points that the user has earnt for this section */
  points?: Maybe<Scalars['Int']>;
  reviewNote?: Maybe<ReviewNote>;
  /** The location of this lesson in the level lessons */
  lessonProgress: LessonProgress;
}

/** Determines the type of a courseware lesson section */
export enum SectionType {
  /** A grammar section */
  Grammar = 'grammar',
  /** A speaking section */
  Speaking = 'speaking',
  /** A listening section */
  Listening = 'listening',
  /** A vocabulary section */
  Vocabulary = 'vocabulary',
  /** A reading section */
  Reading = 'reading',
  /** A expressions section */
  Expressions = 'expressions',
  /** A quiz section */
  Quiz = 'quiz',
  /** A skills section */
  Skills = 'skills',
  /** A finalTask section */
  FinalTask = 'finalTask',
}

export interface SectionTypeStat {
  __typename?: 'SectionTypeStat';
  sectionType: Scalars['String'];
  /** score for this section type, between 0 and 1 */
  score: Scalars['Float'];
}

/** Additional data returned when doing transcription */
export interface SpeechTranscriptionData {
  __typename?: 'SpeechTranscriptionData';
  /** The transcription of the audio file */
  transcription: Scalars['String'];
}

/** Additional data returned when doing word scoring */
export interface SpeechWordScoreData {
  __typename?: 'SpeechWordScoreData';
  /** The transcription of the audio file */
  wordScores: Array<Maybe<WordScore>>;
  /** Threshold level to determine if a word is pronounced correctly */
  wordThreshold: Scalars['Float'];
}

export interface StartStudySessionInput {
  studySectionToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface StartStudySessionPayload {
  __typename?: 'StartStudySessionPayload';
  viewer: Viewer;
  studySession?: Maybe<StudySession>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface StripeCheckoutCreditBundle extends CreditBundle {
  __typename?: 'StripeCheckoutCreditBundle';
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  skuId: Scalars['String'];
}

export interface StripeCheckoutPaymentProvider {
  __typename?: 'StripeCheckoutPaymentProvider';
  creditBundles: Array<StripeCheckoutCreditBundle>;
}

export interface StripeCheckoutSession {
  __typename?: 'StripeCheckoutSession';
  identifier: Scalars['String'];
  baseUrl: Scalars['String'];
  successUrl: Scalars['String'];
  cancelUrl: Scalars['String'];
}

/** Study reminder */
export interface StudyReminder {
  __typename?: 'StudyReminder';
  /** Messages for the study reminders */
  messages: StudyReminderMessages;
}

/** Recurring notifications to remind the user to study */
export interface StudyReminderConfig {
  __typename?: 'StudyReminderConfig';
  /** Version of the reminder messages */
  currentMessagesVersion: Scalars['Int'];
}

/** Messages to be used for the study reminders */
export interface StudyReminderMessages {
  __typename?: 'StudyReminderMessages';
  /** Messages with a nice tone */
  nice: Array<Scalars['String']>;
  /** Messages with a nasty tone */
  nasty: Array<Scalars['String']>;
}

export interface StudySection {
  /** The ID of an object */
  id: Scalars['ID'];
  dialog: Dialog;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  token: Scalars['String'];
}

export interface StudySession extends Node {
  __typename?: 'StudySession';
  /** The ID of an object */
  id: Scalars['ID'];
  pointsEarned?: Maybe<Scalars['Int']>;
}

export interface StudySet extends Node {
  __typename?: 'StudySet';
  /** The ID of an object */
  id: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
  title: StudySetTitle;
  sections: Array<StudySetSection>;
}

/** A chat for studying study sets */
export interface StudySetChat extends Chat, Node {
  __typename?: 'StudySetChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
  studySet?: Maybe<StudySet>;
  hasEnoughData: Scalars['Boolean'];
  botType: Scalars['String'];
}

export interface StudySetSection {
  __typename?: 'StudySetSection';
  title: StudySetSectionTitle;
  isCompleted: Scalars['Boolean'];
  sectionType: StudySetSectionType;
  role: StudySetSectionRole;
}

export enum StudySetSectionRole {
  Warmup = 'WARMUP',
  Focus = 'FOCUS',
  Review = 'REVIEW',
}

export interface StudySetSectionTitle {
  __typename?: 'StudySetSectionTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export enum StudySetSectionType {
  Pronunciation = 'PRONUNCIATION',
  Vocab = 'VOCAB',
  Listening = 'LISTENING',
  Review = 'REVIEW',
}

export interface StudySetTitle {
  __typename?: 'StudySetTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface SubmitClassReviewInput {
  classBookingId: Scalars['ID'];
  /** 0-5 */
  callQuality: Scalars['Int'];
  /** 0-5 */
  classQuality: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SubmitClassReviewPayload {
  __typename?: 'SubmitClassReviewPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SubmitDailyCheckinInput {
  sectionId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SubmitDailyCheckinPayload {
  __typename?: 'SubmitDailyCheckinPayload';
  viewer: Viewer;
  dailyCheckin?: Maybe<DailyCheckin>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SubmitTeacherQuestionInput {
  question: Scalars['String'];
  studySessionId?: Maybe<Scalars['ID']>;
  dialogStatementIdentifier?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SubmitTeacherQuestionPayload {
  __typename?: 'SubmitTeacherQuestionPayload';
  askATeacherTicket?: Maybe<AskATeacherTicket>;
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface TeacherChat extends Chat, Node {
  __typename?: 'TeacherChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
}

export interface TextMessageItem {
  __typename?: 'TextMessageItem';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface TextReviewContentItem {
  __typename?: 'TextReviewContentItem';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

/** A translation object */
export interface Translation {
  __typename?: 'Translation';
  /** An array of translations for the requested word */
  translations: Array<Scalars['String']>;
  /** Localized and English definitions for the requested word */
  definitions: DictionaryDefinitions;
  /** The url for the audio for the requested word */
  audioUrl?: Maybe<Scalars['String']>;
  /** The pronunciation for the requested word */
  pronunciation?: Maybe<Scalars['String']>;
}

export interface UpdateAccountInfoInput {
  name?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  region?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateAccountInfoPayload {
  __typename?: 'UpdateAccountInfoPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateAnnotationForReviewNoteInput {
  reviewNoteId: Scalars['ID'];
  annotation: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateAnnotationForReviewNotePayload {
  __typename?: 'UpdateAnnotationForReviewNotePayload';
  reviewNote?: Maybe<ReviewNote>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateCurrentProgressSectionInput {
  coursewareSectionId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateCurrentProgressSectionPayload {
  __typename?: 'UpdateCurrentProgressSectionPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

/** The type of the update available for the user */
export enum UpdateType {
  /** The update must be performed to use the app */
  Forced = 'forced',
  /** The update is not required to continue to use the app */
  Optional = 'optional',
  /** There is no update available to the client */
  None = 'none',
}

export interface User extends Node {
  __typename?: 'User';
  /** The ID of an object */
  id: Scalars['ID'];
  legacyId: Scalars['String'];
  created?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  isIdentified?: Maybe<Scalars['Boolean']>;
  countryCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  premium?: Maybe<Premium>;
  credits?: Maybe<Scalars['Int']>;
  progress?: Maybe<Progress>;
  classBookingHistory?: Maybe<ClassBookingConnection>;
  languageBuddyChats: Array<LanguageBuddyChat>;
  sectionTypeStats?: Maybe<Array<SectionTypeStat>>;
  reportedCount: Scalars['Int'];
  isBanned: Scalars['Boolean'];
}

export interface UserClassBookingHistoryArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}

/** A chat between two users */
export interface UserChat extends Chat, Node {
  __typename?: 'UserChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
  /** The legacy id of the user chat */
  chatId: Scalars['Guid'];
  /** The text to use as an icebreaker for the conversation */
  iceBreaker?: Maybe<Scalars['String']>;
  participants: Array<UserChatParticipant>;
  messages: UserChatMessageConnection;
}

/** A chat between two users */
export interface UserChatMessagesArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}

/** A representation of a chat message between peers */
export interface UserChatMessage extends Node {
  __typename?: 'UserChatMessage';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The user who sent the message */
  fromUser: User;
  /** The message contents */
  body: Scalars['Dom'];
  created: Scalars['DateTime'];
}

/** A connection to a list of items. */
export interface UserChatMessageConnection {
  __typename?: 'UserChatMessageConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserChatMessageEdge>>>;
}

/** An edge in a connection. */
export interface UserChatMessageEdge {
  __typename?: 'UserChatMessageEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserChatMessage>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export interface UserChatParticipant {
  __typename?: 'UserChatParticipant';
  user: User;
}

export interface UserIdLookup {
  __typename?: 'UserIdLookup';
  result?: Maybe<User>;
}

export interface UserPhoneLookup {
  __typename?: 'UserPhoneLookup';
  result?: Maybe<User>;
}

/** A user to user chat */
export interface UserToUserChat {
  __typename?: 'UserToUserChat';
  /** The chat participants */
  users?: Maybe<Array<Maybe<ChatUser>>>;
  /** A flag to show the chat has been reported */
  reported?: Maybe<Scalars['Boolean']>;
}

export interface ValidateGooglePurchaseInput {
  token: Scalars['String'];
  productId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ValidateGooglePurchasePayload {
  __typename?: 'ValidateGooglePurchasePayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ValidatePurchaseInput {
  receipt: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ValidatePurchasePayload {
  __typename?: 'ValidatePurchasePayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface VideoMessageItem {
  __typename?: 'VideoMessageItem';
  url: Scalars['String'];
  coverImageUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  aspect?: Maybe<Scalars['Float']>;
  vocab?: Maybe<Array<VideoVocabItem>>;
}

export interface VideoReviewContentItem {
  __typename?: 'VideoReviewContentItem';
  url: Scalars['String'];
  coverImageUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  aspect?: Maybe<Scalars['Float']>;
  vocab?: Maybe<Array<VideoReviewContentItemVideoVocab>>;
}

export interface VideoReviewContentItemVideoVocab {
  __typename?: 'VideoReviewContentItemVideoVocab';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
  audioUrl: Scalars['String'];
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
}

export interface VideoVocabItem {
  __typename?: 'VideoVocabItem';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
  audioUrl: Scalars['String'];
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface Viewer {
  __typename?: 'Viewer';
  /** Get a redeemable gift */
  dailyGift?: Maybe<DailyPointsGift>;
  /** The user's current daily checkin streak */
  checkinStreak?: Maybe<Scalars['Int']>;
  lastCheckinDate?: Maybe<Scalars['Date']>;
  pastDailyCheckins: Array<DailyCheckin>;
  /** Current progress for a user */
  currentProgress: Progress;
  /** The list of chats available to the user */
  chats: Array<Chat>;
  homescreenActionButton?: Maybe<HomescreenActionButton>;
  dynamicStrings?: Maybe<DynamicStrings>;
  allBucketAssignments?: Maybe<Array<BucketAssignment>>;
  lockedInBucketAssignments?: Maybe<Array<BucketAssignment>>;
  paymentProvider?: Maybe<PaymentProvider>;
  dynamicContent?: Maybe<DynamicContent>;
  reviewNotebooks: Array<ReviewNotebook>;
  recommendation?: Maybe<Recommendation>;
  admin?: Maybe<Admin>;
  availableClassTopics: Array<ClassTopic>;
  upcomingClassBookings: Array<ClassBooking>;
  inProgressClassBooking?: Maybe<ClassBooking>;
  classBookingHistory: ClassBookingConnection;
  /** Lookup a word in the dictionary */
  dictionaryLookup?: Maybe<DictionaryWord>;
  /** All available level options */
  levelOptionsInformation: Array<LevelOptionInformation>;
  statistics: ViewerStatistics;
  me?: Maybe<User>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerPastDailyCheckinsArgs {
  numDays?: Maybe<Scalars['Int']>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerClassBookingHistoryArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerDictionaryLookupArgs {
  word: Scalars['String'];
}

export interface ViewerStatistics {
  __typename?: 'ViewerStatistics';
  studyRecordsLength: Scalars['Int'];
}

export interface VoucherIap {
  __typename?: 'VoucherIAP';
  premiumPricePlans: Array<PremiumPricePlanVoucher>;
}

export enum VoucherProduct {
  FiveMinutes = 'FIVE_MINUTES',
  OneHour = 'ONE_HOUR',
  TwelveHours = 'TWELVE_HOURS',
  SevenDays = 'SEVEN_DAYS',
  OneMonth = 'ONE_MONTH',
  ThreeMonths = 'THREE_MONTHS',
  OneYear = 'ONE_YEAR',
  Lifetime = 'LIFETIME',
  OneHourTeacher = 'ONE_HOUR_TEACHER',
  OneYearTeacher = 'ONE_YEAR_TEACHER',
  OneYearTeacher_25Credits = 'ONE_YEAR_TEACHER_25_CREDITS',
}

export interface WebLinkMessageItem {
  __typename?: 'WebLinkMessageItem';
  url: Scalars['String'];
  title: WebLinkMessageItemTitle;
}

export interface WebLinkMessageItemTitle {
  __typename?: 'WebLinkMessageItemTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface WebLinkReviewContentItem {
  __typename?: 'WebLinkReviewContentItem';
  url: Scalars['String'];
  title: WebLinkReviewContentItemTitle;
}

export interface WebLinkReviewContentItemTitle {
  __typename?: 'WebLinkReviewContentItemTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface WordPronunciation {
  __typename?: 'WordPronunciation';
  word: Scalars['String'];
  pronunciation?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['String']>;
}

export interface WordPronunciationArg {
  word: Scalars['String'];
}

/** An object with speech grading results for a given word  */
export interface WordScore {
  __typename?: 'WordScore';
  /** The word being evaluated */
  word: Scalars['String'];
  /** Speech score of given word, from 0 - 1 */
  score: Scalars['Float'];
}

export type BookingItemListItemBookingFragment = {
  __typename: 'ClassBooking';
  id: string;
  status: ClassBookingStatus;
  created: any;
  bookingTimeslot: {
    __typename: 'BookingTimeslot';
    startTime: any;
    durationInMinutes: number;
  };
  classTopic: Maybe<{ __typename: 'ClassTopic'; id: string; title: string }>;
  teacher: Maybe<{ __typename: 'Admin'; id: string; email: string }>;
  user: Maybe<{
    __typename: 'User';
    id: string;
    name: Maybe<string>;
    progress: Maybe<{
      __typename: 'Progress';
      sectionProgress: Maybe<{
        __typename: 'SectionProgress';
        section: {
          __typename: 'CoursewareStudySection';
          lesson: {
            __typename: 'CoursewareLesson';
            level: { __typename: 'Level'; number: number };
          };
        };
      }>;
    }>;
  }>;
};

export type ClassBookingUserInfoFragment = {
  __typename: 'User';
  id: string;
  name: Maybe<string>;
  region: Maybe<string>;
  created: Maybe<any>;
  credits: Maybe<number>;
  progress: Maybe<{
    __typename: 'Progress';
    currentLevelNumber: Maybe<number>;
    points: Maybe<number>;
  }>;
  premium: Maybe<{
    __typename: 'Premium';
    isEnabled: boolean;
    endTime: Maybe<any>;
    planType: Maybe<PremiumPlanType>;
  }>;
};

export type LanguageBuddyChatFragmentFragment = {
  __typename: 'LanguageBuddyChat';
  id: string;
  created: any;
  isReported: boolean;
  users: Array<{ __typename: 'User'; id: string; name: Maybe<string> }>;
  messages: Array<{
    __typename: 'LanguageBuddyChatMessage';
    id: string;
    created: any;
    content: string;
    user: Maybe<{ __typename: 'User'; id: string; name: Maybe<string> }>;
  }>;
};

export type GetLevelsQueryVariables = {};

export type GetLevelsQuery = {
  __typename: 'Query';
  levels: Maybe<
    Array<
      Maybe<{
        __typename: 'Level';
        id: string;
        number: number;
        description: Maybe<string>;
      }>
    >
  >;
};

export type UserFragment = { __typename: 'User' } & UserInfoUserFragment &
  UserChatsInfoFragment &
  UserStudyHistoryUserFragment;

export type UserQueryVariables = {
  id: Scalars['ID'];
  canReadUserMessages?: Scalars['Boolean'];
  canReadUserData?: Scalars['Boolean'];
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type UserQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin' }
    | { __typename: 'CoursewareStudySection' }
    | { __typename: 'CoursewareLesson' }
    | { __typename: 'Level' }
    | { __typename: 'CoursewareCourse' }
    | { __typename: 'ReviewNote' }
    | { __typename: 'ReviewLesson' }
    | { __typename: 'ReviewNotebook' }
    | { __typename: 'ReviewNotePracticeStudySection' }
    | { __typename: 'ClassTopic' }
    | { __typename: 'ClassBooking' }
    | { __typename: 'ClassBookingNote' }
    | ({ __typename: 'User' } & UserFragment)
    | { __typename: 'LanguageBuddyChat' }
    | { __typename: 'UserChat' }
    | { __typename: 'UserChatMessage' }
    | { __typename: 'StudySession' }
    | { __typename: 'StudySetChat' }
    | { __typename: 'StudySet' }
    | { __typename: 'AssistantChat' }
    | { __typename: 'BugReportingChat' }
    | { __typename: 'CoursewareChat' }
    | { __typename: 'FeedbackChat' }
    | { __typename: 'FeedbackConversationsChat' }
    | { __typename: 'LessonChat' }
    | { __typename: 'PracticeChat' }
    | { __typename: 'PremiumSampleChat' }
    | { __typename: 'TeacherChat' }
  >;
};

export type UserAdminPermissionsQueryVariables = {};

export type UserAdminPermissionsQuery = {
  __typename: 'Query';
  viewer: {
    __typename: 'Viewer';
    admin: Maybe<{
      __typename: 'Admin';
      id: string;
      permissions: Array<AdminPermission>;
      role: AdminRole;
    }>;
  };
};

export type UserChatsInfoFragment = {
  __typename: 'User';
  id: string;
  isBanned: boolean;
  reportedCount: number;
  languageBuddyChats: Array<{
    __typename: 'LanguageBuddyChat';
    id: string;
    isReported: boolean;
    created: any;
    users: Array<{ __typename: 'User'; id: string; name: Maybe<string> }>;
    messages: Array<{ __typename: 'LanguageBuddyChatMessage'; id: string }>;
  }>;
};

export type UserInfoUserFragment = {
  __typename: 'User';
  id: string;
  name: Maybe<string>;
  region: Maybe<string>;
  created: Maybe<any>;
  isIdentified: Maybe<boolean>;
  credits: Maybe<number>;
  countryCode: Maybe<string>;
  phoneNumber: Maybe<string>;
  birthday: Maybe<any>;
  progress: Maybe<{
    __typename: 'Progress';
    currentLevelNumber: Maybe<number>;
    points: Maybe<number>;
  }>;
  premium: Maybe<{
    __typename: 'Premium';
    isEnabled: boolean;
    endTime: Maybe<any>;
    planType: Maybe<PremiumPlanType>;
  }>;
};

export type UserInfoUpgradeUserPremiumMutationVariables = {
  input: AdminUpgradeUserInput;
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type UserInfoUpgradeUserPremiumMutation = {
  __typename: 'Mutation';
  adminUpgradeUser: Maybe<{
    __typename: 'AdminUpgradeUserPayload';
    user: Maybe<
      {
        __typename: 'User';
        id: string;
        premium: Maybe<{ __typename: 'Premium'; isEnabled: boolean }>;
      } & UserInfoUserFragment
    >;
  }>;
};

export type UserInfoAddCreditsMutationVariables = {
  input: AdminAddCreditsInput;
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type UserInfoAddCreditsMutation = {
  __typename: 'Mutation';
  adminAddCredits: Maybe<{
    __typename: 'AdminAddCreditsPayload';
    user: Maybe<
      {
        __typename: 'User';
        id: string;
        premium: Maybe<{ __typename: 'Premium'; isEnabled: boolean }>;
      } & UserInfoUserFragment
    >;
  }>;
};

export type UserInfoUpdateUserLevelMutationVariables = {
  input: AdminUpdateUserLevelInput;
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type UserInfoUpdateUserLevelMutation = {
  __typename: 'Mutation';
  adminUpdateUserLevel: Maybe<{
    __typename: 'AdminUpdateUserLevelPayload';
    user: Maybe<{ __typename: 'User' } & UserInfoUserFragment>;
  }>;
};

export type UserStudyHistoryUserFragment = { __typename: 'User'; id: string };

export type UserStudyHistoryQueryVariables = {
  userId: Scalars['ID'];
  cursor?: Maybe<Scalars['String']>;
};

export type UserStudyHistoryQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin'; id: string }
    | { __typename: 'CoursewareStudySection'; id: string }
    | { __typename: 'CoursewareLesson'; id: string }
    | { __typename: 'Level'; id: string }
    | { __typename: 'CoursewareCourse'; id: string }
    | { __typename: 'ReviewNote'; id: string }
    | { __typename: 'ReviewLesson'; id: string }
    | { __typename: 'ReviewNotebook'; id: string }
    | { __typename: 'ReviewNotePracticeStudySection'; id: string }
    | { __typename: 'ClassTopic'; id: string }
    | { __typename: 'ClassBooking'; id: string }
    | { __typename: 'ClassBookingNote'; id: string }
    | {
        __typename: 'User';
        id: string;
        progress: Maybe<{
          __typename: 'Progress';
          lessonProgressHistory: Maybe<{
            __typename: 'LessonProgressConnection';
            edges: Maybe<
              Array<
                Maybe<{
                  __typename: 'LessonProgressEdge';
                  node: Maybe<
                    {
                      __typename: 'LessonProgress';
                      id: string;
                    } & UserStudyHistoryRowLessonProgressFragment
                  >;
                }>
              >
            >;
            pageInfo: {
              __typename: 'PageInfo';
              endCursor: Maybe<string>;
              hasNextPage: boolean;
            };
          }>;
        }>;
      }
    | { __typename: 'LanguageBuddyChat'; id: string }
    | { __typename: 'UserChat'; id: string }
    | { __typename: 'UserChatMessage'; id: string }
    | { __typename: 'StudySession'; id: string }
    | { __typename: 'StudySetChat'; id: string }
    | { __typename: 'StudySet'; id: string }
    | { __typename: 'AssistantChat'; id: string }
    | { __typename: 'BugReportingChat'; id: string }
    | { __typename: 'CoursewareChat'; id: string }
    | { __typename: 'FeedbackChat'; id: string }
    | { __typename: 'FeedbackConversationsChat'; id: string }
    | { __typename: 'LessonChat'; id: string }
    | { __typename: 'PracticeChat'; id: string }
    | { __typename: 'PremiumSampleChat'; id: string }
    | { __typename: 'TeacherChat'; id: string }
  >;
};

export type UserStudyHistoryRowLessonProgressFragment = {
  __typename: 'LessonProgress';
  id: string;
  startDate: Maybe<any>;
  completedDate: Maybe<any>;
  lesson: {
    __typename: 'CoursewareLesson';
    title: string;
    number: number;
    level: { __typename: 'Level'; number: number };
  };
  sectionTypeStats: Maybe<
    Array<{ __typename: 'SectionTypeStat'; sectionType: string; score: number }>
  >;
};

export type UserBookingsHistoryQueryVariables = {
  id: Scalars['ID'];
};

export type UserBookingsHistoryQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin' }
    | { __typename: 'CoursewareStudySection' }
    | { __typename: 'CoursewareLesson' }
    | { __typename: 'Level' }
    | { __typename: 'CoursewareCourse' }
    | { __typename: 'ReviewNote' }
    | { __typename: 'ReviewLesson' }
    | { __typename: 'ReviewNotebook' }
    | { __typename: 'ReviewNotePracticeStudySection' }
    | { __typename: 'ClassTopic' }
    | { __typename: 'ClassBooking' }
    | { __typename: 'ClassBookingNote' }
    | {
        __typename: 'User';
        id: string;
        classBookingHistory: Maybe<{
          __typename: 'ClassBookingConnection';
          edges: Maybe<
            Array<
              Maybe<{
                __typename: 'ClassBookingEdge';
                node: Maybe<{
                  __typename: 'ClassBooking';
                  id: string;
                  notes: Array<{
                    __typename: 'ClassBookingNote';
                    id: string;
                    note: string;
                  }>;
                  bookingTimeslot: {
                    __typename: 'BookingTimeslot';
                    startTime: any;
                    durationInMinutes: number;
                  };
                  classTopic: Maybe<{
                    __typename: 'ClassTopic';
                    id: string;
                    title: string;
                    lesson: Maybe<{
                      __typename: 'CoursewareLesson';
                      id: string;
                      number: number;
                      level: {
                        __typename: 'Level';
                        id: string;
                        number: number;
                      };
                    }>;
                  }>;
                }>;
              }>
            >
          >;
        }>;
      }
    | { __typename: 'LanguageBuddyChat' }
    | { __typename: 'UserChat' }
    | { __typename: 'UserChatMessage' }
    | { __typename: 'StudySession' }
    | { __typename: 'StudySetChat' }
    | { __typename: 'StudySet' }
    | { __typename: 'AssistantChat' }
    | { __typename: 'BugReportingChat' }
    | { __typename: 'CoursewareChat' }
    | { __typename: 'FeedbackChat' }
    | { __typename: 'FeedbackConversationsChat' }
    | { __typename: 'LessonChat' }
    | { __typename: 'PracticeChat' }
    | { __typename: 'PremiumSampleChat' }
    | { __typename: 'TeacherChat' }
  >;
};

export type UserClassBookingHistoryFragment = {
  __typename: 'User';
  classBookingHistory: Maybe<{
    __typename: 'ClassBookingConnection';
    edges: Maybe<
      Array<
        Maybe<{
          __typename: 'ClassBookingEdge';
          node: Maybe<{
            __typename: 'ClassBooking';
            id: string;
            bookingTimeslot: { __typename: 'BookingTimeslot'; startTime: any };
            classTopic: Maybe<{
              __typename: 'ClassTopic';
              id: string;
              title: string;
            }>;
          }>;
        }>
      >
    >;
    pageInfo: {
      __typename: 'PageInfo';
      endCursor: Maybe<string>;
      hasNextPage: boolean;
    };
  }>;
};

export type HeaderViewerQueryQueryVariables = {};

export type HeaderViewerQueryQuery = {
  __typename: 'Query';
  viewer: {
    __typename: 'Viewer';
    admin: Maybe<{ __typename: 'Admin'; id: string; email: string }>;
  };
};

export type AudioReviewContentItemFragmentFragment = {
  __typename: 'AudioReviewContentItem';
  url: string;
  duration: Maybe<number>;
  transcripts: Maybe<
    Array<{
      __typename: 'AudioReviewContentItemTranscript';
      text: string;
      localizedText: Maybe<string>;
    }>
  >;
};

export type AudioCarouselReviewContentItemFragmentFragment = {
  __typename: 'AudioCarouselReviewContentItem';
  items: Array<
    {
      __typename: 'AudioReviewContentItem';
    } & AudioReviewContentItemFragmentFragment
  >;
};

export type CardReviewContentItemFragmentFragment = {
  __typename: 'CardReviewContentItem';
  title: {
    __typename: 'CardReviewContentItemTitle';
    text: string;
    localizedText: Maybe<string>;
  };
  items: Array<
    | {
        __typename: 'CardReviewContentItemBodyImage';
        url: string;
        aspect: Maybe<number>;
      }
    | {
        __typename: 'CardReviewContentItemBodyText';
        text: string;
        localizedText: Maybe<string>;
      }
  >;
  subtitle: Maybe<{
    __typename: 'CardReviewContentItemSubitle';
    text: string;
    localizedText: Maybe<string>;
  }>;
  image: Maybe<{
    __typename: 'CardReviewContentItemImage';
    url: string;
    aspect: Maybe<number>;
  }>;
  audio: Maybe<{
    __typename: 'CardReviewContentItemAudio';
    url: string;
    duration: Maybe<number>;
  }>;
};

export type CardCarouselReviewContentItemFragmentFragment = {
  __typename: 'CardCarouselReviewContentItem';
  items: Array<
    {
      __typename: 'CardReviewContentItem';
    } & CardReviewContentItemFragmentFragment
  >;
};

type ReviewContentItemFragment_TextReviewContentItem_Fragment = {
  __typename: 'TextReviewContentItem';
} & TextReviewContentItemFragmentFragment;

type ReviewContentItemFragment_GifReviewContentItem_Fragment = {
  __typename: 'GifReviewContentItem';
} & GifReviewContentItemFragmentFragment;

type ReviewContentItemFragment_AudioReviewContentItem_Fragment = {
  __typename: 'AudioReviewContentItem';
} & AudioReviewContentItemFragmentFragment;

type ReviewContentItemFragment_AudioCarouselReviewContentItem_Fragment = {
  __typename: 'AudioCarouselReviewContentItem';
} & AudioCarouselReviewContentItemFragmentFragment;

type ReviewContentItemFragment_WebLinkReviewContentItem_Fragment = {
  __typename: 'WebLinkReviewContentItem';
} & WebLinkReviewContentItemFragmentFragment;

type ReviewContentItemFragment_VideoReviewContentItem_Fragment = {
  __typename: 'VideoReviewContentItem';
} & VideoReviewContentItemFragmentFragment;

type ReviewContentItemFragment_ImageReviewContentItem_Fragment = {
  __typename: 'ImageReviewContentItem';
} & ImageReviewContentItemFragmentFragment;

type ReviewContentItemFragment_CardReviewContentItem_Fragment = {
  __typename: 'CardReviewContentItem';
} & CardReviewContentItemFragmentFragment;

type ReviewContentItemFragment_CardCarouselReviewContentItem_Fragment = {
  __typename: 'CardCarouselReviewContentItem';
} & CardCarouselReviewContentItemFragmentFragment;

export type ReviewContentItemFragmentFragment =
  | ReviewContentItemFragment_TextReviewContentItem_Fragment
  | ReviewContentItemFragment_GifReviewContentItem_Fragment
  | ReviewContentItemFragment_AudioReviewContentItem_Fragment
  | ReviewContentItemFragment_AudioCarouselReviewContentItem_Fragment
  | ReviewContentItemFragment_WebLinkReviewContentItem_Fragment
  | ReviewContentItemFragment_VideoReviewContentItem_Fragment
  | ReviewContentItemFragment_ImageReviewContentItem_Fragment
  | ReviewContentItemFragment_CardReviewContentItem_Fragment
  | ReviewContentItemFragment_CardCarouselReviewContentItem_Fragment;

type CoursewareReviewSummaryContentItemFragment_ReviewTopic_Fragment = {
  __typename: 'ReviewTopic';
} & ReviewTopicFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_TextReviewContentItem_Fragment = {
  __typename: 'TextReviewContentItem';
} & TextReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_GifReviewContentItem_Fragment = {
  __typename: 'GifReviewContentItem';
} & GifReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_AudioReviewContentItem_Fragment = {
  __typename: 'AudioReviewContentItem';
} & AudioReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_AudioCarouselReviewContentItem_Fragment = {
  __typename: 'AudioCarouselReviewContentItem';
} & AudioCarouselReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_WebLinkReviewContentItem_Fragment = {
  __typename: 'WebLinkReviewContentItem';
} & WebLinkReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_VideoReviewContentItem_Fragment = {
  __typename: 'VideoReviewContentItem';
} & VideoReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_ImageReviewContentItem_Fragment = {
  __typename: 'ImageReviewContentItem';
} & ImageReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_CardReviewContentItem_Fragment = {
  __typename: 'CardReviewContentItem';
} & CardReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_CardCarouselReviewContentItem_Fragment = {
  __typename: 'CardCarouselReviewContentItem';
} & CardCarouselReviewContentItemFragmentFragment;

export type CoursewareReviewSummaryContentItemFragmentFragment =
  | CoursewareReviewSummaryContentItemFragment_ReviewTopic_Fragment
  | CoursewareReviewSummaryContentItemFragment_TextReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_GifReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_AudioReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_AudioCarouselReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_WebLinkReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_VideoReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_ImageReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_CardReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_CardCarouselReviewContentItem_Fragment;

export type ReviewExampleFragmentFragment = {
  __typename: 'ReviewExample';
  exampleTitle: Maybe<{
    __typename: 'ReviewExampleTitle';
    text: string;
    localizedText: Maybe<string>;
  }>;
  items: Array<
    | ({
        __typename: 'TextReviewContentItem';
      } & ReviewContentItemFragment_TextReviewContentItem_Fragment)
    | ({
        __typename: 'GifReviewContentItem';
      } & ReviewContentItemFragment_GifReviewContentItem_Fragment)
    | ({
        __typename: 'AudioReviewContentItem';
      } & ReviewContentItemFragment_AudioReviewContentItem_Fragment)
    | ({
        __typename: 'AudioCarouselReviewContentItem';
      } & ReviewContentItemFragment_AudioCarouselReviewContentItem_Fragment)
    | ({
        __typename: 'WebLinkReviewContentItem';
      } & ReviewContentItemFragment_WebLinkReviewContentItem_Fragment)
    | ({
        __typename: 'VideoReviewContentItem';
      } & ReviewContentItemFragment_VideoReviewContentItem_Fragment)
    | ({
        __typename: 'ImageReviewContentItem';
      } & ReviewContentItemFragment_ImageReviewContentItem_Fragment)
    | ({
        __typename: 'CardReviewContentItem';
      } & ReviewContentItemFragment_CardReviewContentItem_Fragment)
    | ({
        __typename: 'CardCarouselReviewContentItem';
      } & ReviewContentItemFragment_CardCarouselReviewContentItem_Fragment)
  >;
};

export type GifReviewContentItemFragmentFragment = {
  __typename: 'GifReviewContentItem';
  url: string;
  aspect: Maybe<number>;
};

export type ImageReviewContentItemFragmentFragment = {
  __typename: 'ImageReviewContentItem';
  url: string;
  aspect: Maybe<number>;
};

export type TextReviewContentItemFragmentFragment = {
  __typename: 'TextReviewContentItem';
  text: string;
  localizedText: Maybe<string>;
};

export type ReviewTopicFragmentFragment = {
  __typename: 'ReviewTopic';
  topicTitle: Maybe<{
    __typename: 'ReviewTopicTitle';
    text: string;
    localizedText: Maybe<string>;
  }>;
  body: Array<
    | ({ __typename: 'ReviewExample' } & ReviewExampleFragmentFragment)
    | ({
        __typename: 'TextReviewContentItem';
      } & TextReviewContentItemFragmentFragment)
    | ({
        __typename: 'GifReviewContentItem';
      } & GifReviewContentItemFragmentFragment)
    | ({
        __typename: 'AudioReviewContentItem';
      } & AudioReviewContentItemFragmentFragment)
    | ({
        __typename: 'AudioCarouselReviewContentItem';
      } & AudioCarouselReviewContentItemFragmentFragment)
    | ({
        __typename: 'WebLinkReviewContentItem';
      } & WebLinkReviewContentItemFragmentFragment)
    | ({
        __typename: 'VideoReviewContentItem';
      } & VideoReviewContentItemFragmentFragment)
    | ({
        __typename: 'ImageReviewContentItem';
      } & ImageReviewContentItemFragmentFragment)
    | ({
        __typename: 'CardReviewContentItem';
      } & CardReviewContentItemFragmentFragment)
    | ({
        __typename: 'CardCarouselReviewContentItem';
      } & CardCarouselReviewContentItemFragmentFragment)
  >;
};

export type VideoReviewContentItemFragmentFragment = {
  __typename: 'VideoReviewContentItem';
  url: string;
  aspect: Maybe<number>;
  duration: Maybe<number>;
};

export type VocabChipWithDialogItemFragment = {
  __typename: 'CoursewareVocab';
  id: string;
  word: string;
  translation: Maybe<string>;
  imageUrl: Maybe<string>;
  audioUrl: Maybe<string>;
  phonetics: Maybe<string>;
  definition: {
    __typename: 'CoursewareVocabDefinition';
    text: string;
    localizedText: Maybe<string>;
  };
  examples: Maybe<
    Array<{
      __typename: 'CoursewareVocabExample';
      text: string;
      localizedText: Maybe<string>;
    }>
  >;
};

export type WebLinkReviewContentItemFragmentFragment = {
  __typename: 'WebLinkReviewContentItem';
  url: string;
  title: {
    __typename: 'WebLinkReviewContentItemTitle';
    text: string;
    localizedText: Maybe<string>;
  };
};

export type AudioCarouselMessageItemFragmentFragment = {
  __typename: 'AudioCarouselMessageItem';
  items: Array<
    { __typename: 'AudioMessageItem' } & AudioMessageItemFragmentFragment
  >;
};

export type AudioMessageItemFragmentFragment = {
  __typename: 'AudioMessageItem';
  url: string;
  duration: Maybe<number>;
  transcripts: Maybe<
    Array<{
      __typename: 'AudioMessageItemTranscriptItem';
      text: string;
      localizedText: Maybe<string>;
    }>
  >;
};

export type CardCarouselMessageItemFragmentFragment = {
  __typename: 'CardCarouselMessageItem';
  items: Array<
    { __typename: 'CardMessageItem' } & CardMessageItemFragmentFragment
  >;
};

export type CardMessageItemFragmentFragment = {
  __typename: 'CardMessageItem';
  title: {
    __typename: 'CardMessageItemTitle';
    text: string;
    localizedText: Maybe<string>;
  };
  items: Array<
    | {
        __typename: 'CardMessageItemBodyImage';
        url: string;
        aspect: Maybe<number>;
      }
    | {
        __typename: 'CardMessageItemBodyText';
        text: string;
        localizedText: Maybe<string>;
      }
  >;
  subtitle: Maybe<{
    __typename: 'CardMessageItemSubtitle';
    text: string;
    localizedText: Maybe<string>;
  }>;
  image: Maybe<{
    __typename: 'CardMessageItemImage';
    url: string;
    aspect: Maybe<number>;
  }>;
  audio: Maybe<{ __typename: 'CardMessageItemAudio'; url: string }>;
};

export type DialogFragmentFragment = {
  __typename: 'Dialog';
  segments: Array<
    | ({ __typename: 'DialogBaseSegment' } & DialogBaseSegmentFragmentFragment)
    | { __typename: 'DialogPaywallSegment' }
    | ({
        __typename: 'DialogQuestionSegment';
      } & DialogQuestionSegmentFragmentFragment)
  >;
};

export type DialogBaseSegmentFragmentFragment = {
  __typename: 'DialogBaseSegment';
  statements: Array<
    { __typename: 'DialogStatement' } & DialogStatementFragmentFragment
  >;
};

export type DialogMultipleChoiceTextQuestionFragmentFragment = {
  __typename: 'DialogMultipleChoiceTextQuestion';
  correctChoiceIndices: Array<number>;
  maxAttempts: Maybe<number>;
  statements: Array<
    { __typename: 'DialogStatement' } & DialogStatementFragmentFragment
  >;
  choices: Array<{
    __typename: 'DialogMultipleChoiceTextChoice';
    text: string;
  }>;
};

export type DialogQuestionSegmentFragmentFragment = {
  __typename: 'DialogQuestionSegment';
  statements: Array<
    { __typename: 'DialogStatement' } & DialogStatementFragmentFragment
  >;
  questions: Array<
    | ({
        __typename: 'DialogMultipleChoiceTextQuestion';
      } & DialogMultipleChoiceTextQuestionFragmentFragment)
    | { __typename: 'DialogScrambleQuestion' }
    | ({
        __typename: 'DialogSpeakingQuestion';
      } & DialogSpeakingQuestionFragmentFragment)
  >;
};

export type DialogSpeakingQuestionFragmentFragment = {
  __typename: 'DialogSpeakingQuestion';
  maxAttempts: Maybe<number>;
  statements: Array<
    { __typename: 'DialogStatement' } & DialogStatementFragmentFragment
  >;
  content: { __typename: 'DialogSpeakingContent'; text: string };
};

export type DialogStatementFragmentFragment = {
  __typename: 'DialogStatement';
  identifier: string;
  item:
    | ({
        __typename: 'TextMessageItem';
      } & MessageItemFragment_TextMessageItem_Fragment)
    | ({
        __typename: 'GifMessageItem';
      } & MessageItemFragment_GifMessageItem_Fragment)
    | ({
        __typename: 'AudioMessageItem';
      } & MessageItemFragment_AudioMessageItem_Fragment)
    | ({
        __typename: 'AudioCarouselMessageItem';
      } & MessageItemFragment_AudioCarouselMessageItem_Fragment)
    | ({
        __typename: 'VideoMessageItem';
      } & MessageItemFragment_VideoMessageItem_Fragment)
    | ({
        __typename: 'WebLinkMessageItem';
      } & MessageItemFragment_WebLinkMessageItem_Fragment)
    | ({
        __typename: 'ImageMessageItem';
      } & MessageItemFragment_ImageMessageItem_Fragment)
    | ({
        __typename: 'CardMessageItem';
      } & MessageItemFragment_CardMessageItem_Fragment)
    | ({
        __typename: 'CardCarouselMessageItem';
      } & MessageItemFragment_CardCarouselMessageItem_Fragment);
  pauseChoice: Maybe<{ __typename: 'PauseChoiceTextOption'; text: string }>;
};

export type GifMessageItemFragmentFragment = {
  __typename: 'GifMessageItem';
  url: string;
  aspect: Maybe<number>;
};

export type ImageMessageItemFragmentFragment = {
  __typename: 'ImageMessageItem';
  url: string;
  aspect: Maybe<number>;
};

type MessageItemFragment_TextMessageItem_Fragment = {
  __typename: 'TextMessageItem';
} & TextMessageItemFragmentFragment;

type MessageItemFragment_GifMessageItem_Fragment = {
  __typename: 'GifMessageItem';
} & GifMessageItemFragmentFragment;

type MessageItemFragment_AudioMessageItem_Fragment = {
  __typename: 'AudioMessageItem';
} & AudioMessageItemFragmentFragment;

type MessageItemFragment_AudioCarouselMessageItem_Fragment = {
  __typename: 'AudioCarouselMessageItem';
} & AudioCarouselMessageItemFragmentFragment;

type MessageItemFragment_VideoMessageItem_Fragment = {
  __typename: 'VideoMessageItem';
} & VideoMessageItemFragmentFragment;

type MessageItemFragment_WebLinkMessageItem_Fragment = {
  __typename: 'WebLinkMessageItem';
} & WebLinkMessageItemFragmentFragment;

type MessageItemFragment_ImageMessageItem_Fragment = {
  __typename: 'ImageMessageItem';
} & ImageMessageItemFragmentFragment;

type MessageItemFragment_CardMessageItem_Fragment = {
  __typename: 'CardMessageItem';
} & CardMessageItemFragmentFragment;

type MessageItemFragment_CardCarouselMessageItem_Fragment = {
  __typename: 'CardCarouselMessageItem';
} & CardCarouselMessageItemFragmentFragment;

export type MessageItemFragmentFragment =
  | MessageItemFragment_TextMessageItem_Fragment
  | MessageItemFragment_GifMessageItem_Fragment
  | MessageItemFragment_AudioMessageItem_Fragment
  | MessageItemFragment_AudioCarouselMessageItem_Fragment
  | MessageItemFragment_VideoMessageItem_Fragment
  | MessageItemFragment_WebLinkMessageItem_Fragment
  | MessageItemFragment_ImageMessageItem_Fragment
  | MessageItemFragment_CardMessageItem_Fragment
  | MessageItemFragment_CardCarouselMessageItem_Fragment;

export type TextMessageItemFragmentFragment = {
  __typename: 'TextMessageItem';
  text: string;
  localizedText: Maybe<string>;
};

export type VideoMessageItemFragmentFragment = {
  __typename: 'VideoMessageItem';
  url: string;
  aspect: Maybe<number>;
  duration: Maybe<number>;
};

export type WebLinkMessageItemFragmentFragment = {
  __typename: 'WebLinkMessageItem';
  url: string;
  title: {
    __typename: 'WebLinkMessageItemTitle';
    text: string;
    localizedText: Maybe<string>;
  };
};

export type AdminDataFragment = {
  __typename: 'Admin';
  id: string;
  email: string;
  role: AdminRole;
  permissions: Array<AdminPermission>;
};

export type AdminUpdateAdminMutationVariables = {
  input: AdminUpdateAdminInput;
};

export type AdminUpdateAdminMutation = {
  __typename: 'Mutation';
  adminUpdateAdmin: Maybe<{
    __typename: 'AdminUpdateAdminPayload';
    admin: Maybe<{ __typename: 'Admin' } & AdminDataFragment>;
  }>;
};

export type AdminEmailLookupQueryVariables = {
  email: Scalars['String'];
};

export type AdminEmailLookupQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    adminEmailLookup: Maybe<{
      __typename: 'AdminEmailLookup';
      result: Maybe<{ __typename: 'Admin' } & AdminDataFragment>;
    }>;
  }>;
};

export type AdminUpdatePromoCodeMutationVariables = {
  input: AdminUpdatePromoCodeInput;
};

export type AdminUpdatePromoCodeMutation = {
  __typename: 'Mutation';
  adminUpdatePromoCode: Maybe<{
    __typename: 'AdminUpdatePromoCodePayload';
    promoCode: { __typename: 'PromoCode'; id: string } & PromoCodeInfoFragment;
  }>;
};

export type AdminCreatePromoCodeMutationVariables = {
  input: AdminCreatePromoCodeInput;
};

export type AdminCreatePromoCodeMutation = {
  __typename: 'Mutation';
  adminCreatePromoCode: Maybe<{
    __typename: 'AdminCreatePromoCodePayload';
    promoCode: { __typename: 'PromoCode' } & PromoCodeInfoFragment;
  }>;
};

export type PromoCodeInfoFragment = {
  __typename: 'PromoCode';
  id: string;
  code: string;
  maxRedemptions: Maybe<number>;
  redemptionsRemaining: Maybe<number>;
  title: Maybe<string>;
  created: any;
  product: string;
  orderNumber: Maybe<string>;
  phoneNumber: Maybe<string>;
  status: PromoCodeStatus;
};

export type GetAdminPromoCodesQueryVariables = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  sourceKind?: Maybe<PromoCodeSourceKind>;
  orderId?: Maybe<Scalars['String']>;
};

export type GetAdminPromoCodesQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    adminPromoCodesLookup: {
      __typename: 'PromoCodeConnection';
      edges: Maybe<
        Array<
          Maybe<{
            __typename: 'PromoCodeEdge';
            node: Maybe<{ __typename: 'PromoCode' } & PromoCodeInfoFragment>;
          }>
        >
      >;
      pageInfo: {
        __typename: 'PageInfo';
        hasNextPage: boolean;
        endCursor: Maybe<string>;
      };
    };
  }>;
};

export type AdminRedeemPromoCodeMutationVariables = {
  input: AdminRedeemPromoCodeWithPhoneNumberInput;
  canReadSensitiveUserData?: Maybe<Scalars['Boolean']>;
};

export type AdminRedeemPromoCodeMutation = {
  __typename: 'Mutation';
  adminRedeemPromoCodeWithPhoneNumber: Maybe<{
    __typename: 'AdminRedeemPromoCodeWithPhoneNumberPayload';
    user: { __typename: 'User' } & UserInfoUserFragment;
  }>;
};

export type AdminCancelClassBookingMutationVariables = {
  input: AdminCancelClassBookingInput;
};

export type AdminCancelClassBookingMutation = {
  __typename: 'Mutation';
  adminCancelClassBooking: Maybe<{
    __typename: 'AdminCancelClassBookingPayload';
    booking: Maybe<{ __typename: 'ClassBooking'; status: ClassBookingStatus }>;
  }>;
};

export type ClassBookingFragment = {
  __typename: 'ClassBooking';
  id: string;
  status: ClassBookingStatus;
  channelIdentifier: Maybe<string>;
  classTopic: Maybe<{
    __typename: 'ClassTopic';
    id: string;
    title: string;
    deckEmbedUrl: Maybe<string>;
    lesson: Maybe<{ __typename: 'CoursewareLesson'; id: string }>;
  }>;
  bookingTimeslot: {
    __typename: 'BookingTimeslot';
    startTime: any;
    durationInMinutes: number;
  };
  user: Maybe<
    { __typename: 'User' } & ClassBookingUserInfoFragment &
      UserClassBookingHistoryFragment
  >;
  notes: Array<
    { __typename: 'ClassBookingNote' } & AdminClassBookingNoteFragment
  >;
};

export type ClassBookingViewerFragment = {
  __typename: 'Viewer';
  admin: Maybe<{
    __typename: 'Admin';
    id: string;
    permissions: Array<AdminPermission>;
  }>;
};

export type ClassBookingQueryVariables = {
  id: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type ClassBookingQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin' }
    | { __typename: 'CoursewareStudySection' }
    | { __typename: 'CoursewareLesson' }
    | { __typename: 'Level' }
    | { __typename: 'CoursewareCourse' }
    | { __typename: 'ReviewNote' }
    | { __typename: 'ReviewLesson' }
    | { __typename: 'ReviewNotebook' }
    | { __typename: 'ReviewNotePracticeStudySection' }
    | { __typename: 'ClassTopic' }
    | ({ __typename: 'ClassBooking' } & ClassBookingFragment)
    | { __typename: 'ClassBookingNote' }
    | { __typename: 'User' }
    | { __typename: 'LanguageBuddyChat' }
    | { __typename: 'UserChat' }
    | { __typename: 'UserChatMessage' }
    | { __typename: 'StudySession' }
    | { __typename: 'StudySetChat' }
    | { __typename: 'StudySet' }
    | { __typename: 'AssistantChat' }
    | { __typename: 'BugReportingChat' }
    | { __typename: 'CoursewareChat' }
    | { __typename: 'FeedbackChat' }
    | { __typename: 'FeedbackConversationsChat' }
    | { __typename: 'LessonChat' }
    | { __typename: 'PracticeChat' }
    | { __typename: 'PremiumSampleChat' }
    | { __typename: 'TeacherChat' }
  >;
  viewer: { __typename: 'Viewer' } & ClassBookingViewerFragment;
};

export type JoinVideoClassroomMutationVariables = {
  input: JoinVideoClassroomInput;
};

export type JoinVideoClassroomMutation = {
  __typename: 'Mutation';
  joinVideoClassroom: Maybe<{
    __typename: 'JoinVideoClassroomPayload';
    token: string;
    account: string;
    channel: string;
  }>;
};

export type AdminCreateClassBookingNoteMutationVariables = {
  input: AdminCreateClassBookingNoteInput;
};

export type AdminCreateClassBookingNoteMutation = {
  __typename: 'Mutation';
  adminCreateClassBookingNote: Maybe<{
    __typename: 'AdminCreateClassBookingNotePayload';
    note: Maybe<
      { __typename: 'ClassBookingNote' } & AdminClassBookingNoteFragment
    >;
  }>;
};

export type AdminClassBookingNoteFragment = {
  __typename: 'ClassBookingNote';
  id: string;
  note: string;
};

export type SectionTypeStatsPanelQueryVariables = {
  lessonId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type SectionTypeStatsPanelQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    lessonProgress: Maybe<{
      __typename: 'LessonProgress';
      sectionTypeStats: Maybe<
        Array<{
          __typename: 'SectionTypeStat';
          sectionType: string;
          score: number;
        }>
      >;
    }>;
  }>;
  node: Maybe<
    | { __typename: 'DailyCheckin' }
    | { __typename: 'CoursewareStudySection' }
    | { __typename: 'CoursewareLesson' }
    | { __typename: 'Level' }
    | { __typename: 'CoursewareCourse' }
    | { __typename: 'ReviewNote' }
    | { __typename: 'ReviewLesson' }
    | { __typename: 'ReviewNotebook' }
    | { __typename: 'ReviewNotePracticeStudySection' }
    | { __typename: 'ClassTopic' }
    | { __typename: 'ClassBooking' }
    | { __typename: 'ClassBookingNote' }
    | {
        __typename: 'User';
        id: string;
        sectionTypeStats: Maybe<
          Array<{
            __typename: 'SectionTypeStat';
            sectionType: string;
            score: number;
          }>
        >;
      }
    | { __typename: 'LanguageBuddyChat' }
    | { __typename: 'UserChat' }
    | { __typename: 'UserChatMessage' }
    | { __typename: 'StudySession' }
    | { __typename: 'StudySetChat' }
    | { __typename: 'StudySet' }
    | { __typename: 'AssistantChat' }
    | { __typename: 'BugReportingChat' }
    | { __typename: 'CoursewareChat' }
    | { __typename: 'FeedbackChat' }
    | { __typename: 'FeedbackConversationsChat' }
    | { __typename: 'LessonChat' }
    | { __typename: 'PracticeChat' }
    | { __typename: 'PremiumSampleChat' }
    | { __typename: 'TeacherChat' }
  >;
};

export type CoursewareCoursesQueryVariables = {};

export type CoursewareCoursesQuery = {
  __typename: 'Query';
  courses: Maybe<
    Array<
      Maybe<{
        __typename: 'CoursewareCourse';
        id: string;
        title: string;
        cefrLevel: CefrLevel;
        lessons: Array<{
          __typename: 'CoursewareLesson';
          id: string;
          numberInCourse: number;
        }>;
      }>
    >
  >;
};

export type CoursewareLessonQueryVariables = {
  id: Scalars['ID'];
};

export type CoursewareLessonQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin' }
    | { __typename: 'CoursewareStudySection' }
    | {
        __typename: 'CoursewareLesson';
        id: string;
        title: string;
        numberInCourse: number;
        course: {
          __typename: 'CoursewareCourse';
          id: string;
          title: string;
          cefrLevel: CefrLevel;
        };
        sections: Array<{
          __typename: 'CoursewareStudySection';
          id: string;
          title: string;
        }>;
      }
    | { __typename: 'Level' }
    | { __typename: 'CoursewareCourse' }
    | { __typename: 'ReviewNote' }
    | { __typename: 'ReviewLesson' }
    | { __typename: 'ReviewNotebook' }
    | { __typename: 'ReviewNotePracticeStudySection' }
    | { __typename: 'ClassTopic' }
    | { __typename: 'ClassBooking' }
    | { __typename: 'ClassBookingNote' }
    | { __typename: 'User' }
    | { __typename: 'LanguageBuddyChat' }
    | { __typename: 'UserChat' }
    | { __typename: 'UserChatMessage' }
    | { __typename: 'StudySession' }
    | { __typename: 'StudySetChat' }
    | { __typename: 'StudySet' }
    | { __typename: 'AssistantChat' }
    | { __typename: 'BugReportingChat' }
    | { __typename: 'CoursewareChat' }
    | { __typename: 'FeedbackChat' }
    | { __typename: 'FeedbackConversationsChat' }
    | { __typename: 'LessonChat' }
    | { __typename: 'PracticeChat' }
    | { __typename: 'PremiumSampleChat' }
    | { __typename: 'TeacherChat' }
  >;
};

export type LessonNavQueryVariables = {};

export type LessonNavQuery = {
  __typename: 'Query';
  courses: Maybe<
    Array<
      Maybe<{
        __typename: 'CoursewareCourse';
        id: string;
        lessons: Array<{ __typename: 'CoursewareLesson'; id: string }>;
      }>
    >
  >;
};

export type LoadConversationQueryVariables = {
  id: Scalars['ID'];
};

export type LoadConversationQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin'; id: string }
    | {
        __typename: 'CoursewareStudySection';
        id: string;
        dialog: { __typename: 'Dialog' } & DialogFragmentFragment;
      }
    | { __typename: 'CoursewareLesson'; id: string }
    | { __typename: 'Level'; id: string }
    | { __typename: 'CoursewareCourse'; id: string }
    | { __typename: 'ReviewNote'; id: string }
    | { __typename: 'ReviewLesson'; id: string }
    | { __typename: 'ReviewNotebook'; id: string }
    | { __typename: 'ReviewNotePracticeStudySection'; id: string }
    | { __typename: 'ClassTopic'; id: string }
    | { __typename: 'ClassBooking'; id: string }
    | { __typename: 'ClassBookingNote'; id: string }
    | { __typename: 'User'; id: string }
    | { __typename: 'LanguageBuddyChat'; id: string }
    | { __typename: 'UserChat'; id: string }
    | { __typename: 'UserChatMessage'; id: string }
    | { __typename: 'StudySession'; id: string }
    | { __typename: 'StudySetChat'; id: string }
    | { __typename: 'StudySet'; id: string }
    | { __typename: 'AssistantChat'; id: string }
    | { __typename: 'BugReportingChat'; id: string }
    | { __typename: 'CoursewareChat'; id: string }
    | { __typename: 'FeedbackChat'; id: string }
    | { __typename: 'FeedbackConversationsChat'; id: string }
    | { __typename: 'LessonChat'; id: string }
    | { __typename: 'PracticeChat'; id: string }
    | { __typename: 'PremiumSampleChat'; id: string }
    | { __typename: 'TeacherChat'; id: string }
  >;
};

export type LoadSummaryQueryVariables = {
  id: Scalars['ID'];
};

export type LoadSummaryQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin'; id: string }
    | {
        __typename: 'CoursewareStudySection';
        id: string;
        reviewSummaryContents: Array<
          | ({
              __typename: 'ReviewTopic';
            } & CoursewareReviewSummaryContentItemFragment_ReviewTopic_Fragment)
          | ({
              __typename: 'TextReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_TextReviewContentItem_Fragment)
          | ({
              __typename: 'GifReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_GifReviewContentItem_Fragment)
          | ({
              __typename: 'AudioReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_AudioReviewContentItem_Fragment)
          | ({
              __typename: 'AudioCarouselReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_AudioCarouselReviewContentItem_Fragment)
          | ({
              __typename: 'WebLinkReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_WebLinkReviewContentItem_Fragment)
          | ({
              __typename: 'VideoReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_VideoReviewContentItem_Fragment)
          | ({
              __typename: 'ImageReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_ImageReviewContentItem_Fragment)
          | ({
              __typename: 'CardReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_CardReviewContentItem_Fragment)
          | ({
              __typename: 'CardCarouselReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_CardCarouselReviewContentItem_Fragment)
        >;
      }
    | { __typename: 'CoursewareLesson'; id: string }
    | { __typename: 'Level'; id: string }
    | { __typename: 'CoursewareCourse'; id: string }
    | { __typename: 'ReviewNote'; id: string }
    | { __typename: 'ReviewLesson'; id: string }
    | { __typename: 'ReviewNotebook'; id: string }
    | { __typename: 'ReviewNotePracticeStudySection'; id: string }
    | { __typename: 'ClassTopic'; id: string }
    | { __typename: 'ClassBooking'; id: string }
    | { __typename: 'ClassBookingNote'; id: string }
    | { __typename: 'User'; id: string }
    | { __typename: 'LanguageBuddyChat'; id: string }
    | { __typename: 'UserChat'; id: string }
    | { __typename: 'UserChatMessage'; id: string }
    | { __typename: 'StudySession'; id: string }
    | { __typename: 'StudySetChat'; id: string }
    | { __typename: 'StudySet'; id: string }
    | { __typename: 'AssistantChat'; id: string }
    | { __typename: 'BugReportingChat'; id: string }
    | { __typename: 'CoursewareChat'; id: string }
    | { __typename: 'FeedbackChat'; id: string }
    | { __typename: 'FeedbackConversationsChat'; id: string }
    | { __typename: 'LessonChat'; id: string }
    | { __typename: 'PracticeChat'; id: string }
    | { __typename: 'PremiumSampleChat'; id: string }
    | { __typename: 'TeacherChat'; id: string }
  >;
};

export type LoadSectionInfoQueryVariables = {
  id: Scalars['ID'];
};

export type LoadSectionInfoQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin'; id: string }
    | {
        __typename: 'CoursewareStudySection';
        title: string;
        description: Maybe<string>;
        sectionType: CoursewareStudySectionType;
        implicitVocab: Maybe<Array<string>>;
        id: string;
        vocab: Maybe<
          Array<
            {
              __typename: 'CoursewareVocab';
              id: string;
              word: string;
            } & VocabChipWithDialogItemFragment
          >
        >;
        lesson: {
          __typename: 'CoursewareLesson';
          id: string;
          title: string;
          numberInCourse: number;
          course: { __typename: 'CoursewareCourse'; id: string; title: string };
        };
      }
    | { __typename: 'CoursewareLesson'; id: string }
    | { __typename: 'Level'; id: string }
    | { __typename: 'CoursewareCourse'; id: string }
    | { __typename: 'ReviewNote'; id: string }
    | { __typename: 'ReviewLesson'; id: string }
    | { __typename: 'ReviewNotebook'; id: string }
    | { __typename: 'ReviewNotePracticeStudySection'; id: string }
    | { __typename: 'ClassTopic'; id: string }
    | { __typename: 'ClassBooking'; id: string }
    | { __typename: 'ClassBookingNote'; id: string }
    | { __typename: 'User'; id: string }
    | { __typename: 'LanguageBuddyChat'; id: string }
    | { __typename: 'UserChat'; id: string }
    | { __typename: 'UserChatMessage'; id: string }
    | { __typename: 'StudySession'; id: string }
    | { __typename: 'StudySetChat'; id: string }
    | { __typename: 'StudySet'; id: string }
    | { __typename: 'AssistantChat'; id: string }
    | { __typename: 'BugReportingChat'; id: string }
    | { __typename: 'CoursewareChat'; id: string }
    | { __typename: 'FeedbackChat'; id: string }
    | { __typename: 'FeedbackConversationsChat'; id: string }
    | { __typename: 'LessonChat'; id: string }
    | { __typename: 'PracticeChat'; id: string }
    | { __typename: 'PremiumSampleChat'; id: string }
    | { __typename: 'TeacherChat'; id: string }
  >;
};

export type SectionNavQueryVariables = {};

export type SectionNavQuery = {
  __typename: 'Query';
  courses: Maybe<
    Array<
      Maybe<{
        __typename: 'CoursewareCourse';
        id: string;
        lessons: Array<{
          __typename: 'CoursewareLesson';
          id: string;
          sections: Array<{
            __typename: 'CoursewareStudySection';
            id: string;
            sectionType: CoursewareStudySectionType;
          }>;
        }>;
      }>
    >
  >;
};

export type LanguageBuddyChatQueryVariables = {
  id: Scalars['ID'];
};

export type LanguageBuddyChatQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin' }
    | { __typename: 'CoursewareStudySection' }
    | { __typename: 'CoursewareLesson' }
    | { __typename: 'Level' }
    | { __typename: 'CoursewareCourse' }
    | { __typename: 'ReviewNote' }
    | { __typename: 'ReviewLesson' }
    | { __typename: 'ReviewNotebook' }
    | { __typename: 'ReviewNotePracticeStudySection' }
    | { __typename: 'ClassTopic' }
    | { __typename: 'ClassBooking' }
    | { __typename: 'ClassBookingNote' }
    | { __typename: 'User' }
    | ({ __typename: 'LanguageBuddyChat' } & LanguageBuddyChatFragmentFragment)
    | { __typename: 'UserChat' }
    | { __typename: 'UserChatMessage' }
    | { __typename: 'StudySession' }
    | { __typename: 'StudySetChat' }
    | { __typename: 'StudySet' }
    | { __typename: 'AssistantChat' }
    | { __typename: 'BugReportingChat' }
    | { __typename: 'CoursewareChat' }
    | { __typename: 'FeedbackChat' }
    | { __typename: 'FeedbackConversationsChat' }
    | { __typename: 'LessonChat' }
    | { __typename: 'PracticeChat' }
    | { __typename: 'PremiumSampleChat' }
    | { __typename: 'TeacherChat' }
  >;
};

export type AuthViewerQueryVariables = {};

export type AuthViewerQuery = {
  __typename: 'Query';
  viewer: {
    __typename: 'Viewer';
    admin: Maybe<{ __typename: 'Admin'; id: string; email: string }>;
  };
};

export type AdminBookingsLookupQueryVariables = {
  email?: Maybe<Scalars['String']>;
  startingAfter?: Maybe<Scalars['DateTime']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type AdminBookingsLookupQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    adminBookingsLookup: Maybe<{
      __typename: 'AdminBookingsLookup';
      result: {
        __typename: 'ClassBookingConnection';
        edges: Maybe<
          Array<
            Maybe<{
              __typename: 'ClassBookingEdge';
              node: Maybe<
                {
                  __typename: 'ClassBooking';
                  bookingTimeslot: {
                    __typename: 'BookingTimeslot';
                    startTime: any;
                    duration: number;
                  };
                } & BookingItemListItemBookingFragment
              >;
            }>
          >
        >;
        pageInfo: {
          __typename: 'PageInfo';
          endCursor: Maybe<string>;
          hasNextPage: boolean;
        };
      };
    }>;
  }>;
};

export type AdminViewerQueryQueryVariables = {};

export type AdminViewerQueryQuery = {
  __typename: 'Query';
  viewer: {
    __typename: 'Viewer';
    admin: Maybe<{
      __typename: 'Admin';
      id: string;
      permissions: Array<AdminPermission>;
      email: string;
    }>;
  };
};

export type ChatMessageSearchViewerQueryQueryVariables = {};

export type ChatMessageSearchViewerQueryQuery = {
  __typename: 'Query';
  viewer: {
    __typename: 'Viewer';
    admin: Maybe<{ __typename: 'Admin'; id: string; email: string }>;
  };
};

export type ChatMessageSearchQueryQueryVariables = {
  query?: Maybe<Scalars['String']>;
};

export type ChatMessageSearchQueryQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    chatMessageSearch: Maybe<{
      __typename: 'ChatMessageSearch';
      results: Maybe<{
        __typename: 'LanguageBuddyChatMessageConnection';
        edges: Maybe<
          Array<
            Maybe<{
              __typename: 'LanguageBuddyChatMessageEdge';
              node: Maybe<{
                __typename: 'LanguageBuddyChatMessage';
                id: string;
                content: string;
                created: any;
                user: Maybe<{
                  __typename: 'User';
                  id: string;
                  name: Maybe<string>;
                }>;
                languageBuddyChat: Maybe<{
                  __typename: 'LanguageBuddyChat';
                  id: string;
                  isReported: boolean;
                }>;
              }>;
            }>
          >
        >;
      }>;
    }>;
  }>;
};

export type IndexViewerQueryQueryVariables = {};

export type IndexViewerQueryQuery = {
  __typename: 'Query';
  viewer: {
    __typename: 'Viewer';
    admin: Maybe<{
      __typename: 'Admin';
      id: string;
      permissions: Array<AdminPermission>;
    }>;
  };
};

export type UserLookupViewerQueryQueryVariables = {};

export type UserLookupViewerQueryQuery = {
  __typename: 'Query';
  viewer: {
    __typename: 'Viewer';
    admin: Maybe<{
      __typename: 'Admin';
      id: string;
      email: string;
      permissions: Array<AdminPermission>;
    }>;
  };
};

export type UserPhoneLookupQueryQueryVariables = {
  countryCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  canReadUserMessages?: Scalars['Boolean'];
  canReadUserData?: Scalars['Boolean'];
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type UserPhoneLookupQueryQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    userPhoneLookup: Maybe<{
      __typename: 'UserPhoneLookup';
      result: Maybe<
        {
          __typename: 'User';
          id: string;
          premium: Maybe<{ __typename: 'Premium'; isEnabled: boolean }>;
        } & UserFragment
      >;
    }>;
  }>;
};

export type UserIdLookupQueryQueryVariables = {
  userId: Scalars['String'];
  canReadUserMessages?: Scalars['Boolean'];
  canReadUserData?: Scalars['Boolean'];
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type UserIdLookupQueryQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    userIdLookup: Maybe<{
      __typename: 'UserIdLookup';
      result: Maybe<
        {
          __typename: 'User';
          id: string;
          premium: Maybe<{ __typename: 'Premium'; isEnabled: boolean }>;
        } & UserFragment
      >;
    }>;
  }>;
};
