import React, { useEffect, FormEvent } from 'react';
import {
  Container,
  FormControl,
  TextField,
  InputAdornment,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import gql from 'graphql-tag';

import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { DateTime } from 'luxon';
import { Search } from '@material-ui/icons';
import IndexLayout from '../components/common/Layout';
import Page from '../components/common/Page';
import {
  BookingItemListItem,
  bookingItemListItemBooking,
} from '../components/BookingItemListItem';

import {
  AdminViewerQueryQuery,
  AdminPermission,
  AdminBookingsLookupQuery,
  BookingItemListItemBookingFragment,
} from '../generated/graphql';

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: theme.spacing(2),
  },
  searchInput: {
    width: '100%',
  },
}));

const bookingsLookupQuery = gql`
  query adminBookingsLookup(
    $email: String
    $startingAfter: DateTime
    $after: String
    $first: Int
  ) {
    admin {
      adminBookingsLookup(
        email: $email
        startingAfter: $startingAfter
        after: $after
        first: $first
      ) {
        result {
          edges {
            node {
              ...bookingItemListItemBooking
              bookingTimeslot {
                startTime
                duration
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
  ${bookingItemListItemBooking}
`;

const adminViewerQuery = gql`
  query adminViewerQuery {
    viewer {
      admin {
        id
        permissions
        email
      }
    }
  }
`;

interface IBookingTimeslot {
  startTime: string;
  durationInMinutes: number;
}

const bookingIsFuture = (timeslot?: IBookingTimeslot | null) =>
  timeslot
    ? DateTime.fromISO(timeslot?.startTime).diffNow().valueOf() > 0
    : false;

const bookingIsEnded = (timeslot?: IBookingTimeslot | null) =>
  timeslot
    ? DateTime.fromISO(timeslot?.startTime)
        .plus({ minutes: timeslot?.durationInMinutes })
        .diffNow()
        .valueOf() < 0
    : false;

const AdminLookupPage = () => {
  const styles = useStyles();
  const [email, setEmail] = React.useState<string | undefined>();
  const { data: viewerData } = useQuery<AdminViewerQueryQuery>(
    adminViewerQuery,
  );

  const [getBookings, { data: bookingsData, error, variables }] = useLazyQuery<
    AdminBookingsLookupQuery
  >(bookingsLookupQuery, {
    pollInterval: 10000, // Update every 10 seconds
  });

  const adminIsManager = viewerData?.viewer.admin?.permissions.includes(
    AdminPermission.ManageClassBooking,
  );

  const searchBookings = () => {
    const oneWeekAgo = DateTime.local().minus({ weeks: 1 }).toISO();

    let queryEmail = adminIsManager
      ? undefined
      : viewerData?.viewer.admin?.email;

    if (adminIsManager && email) queryEmail = email;

    if (!adminIsManager) {
      setEmail(queryEmail);
    }

    getBookings({
      variables: { email: queryEmail, startingAfter: oneWeekAgo },
    });
  };

  useEffect(() => {
    searchBookings();
  }, [viewerData]);

  const handleFormSubmitted = (e: FormEvent) => {
    e.preventDefault();
    searchBookings();
  };

  const bookings = bookingsData?.admin?.adminBookingsLookup?.result.edges?.map(
    edge => edge?.node,
  );

  const bookingToComponent = (
    booking: BookingItemListItemBookingFragment | null | undefined,
  ) => booking && <BookingItemListItem key={booking.id} booking={booking} />;

  const futureBookings = bookings
    ?.filter(booking => booking && bookingIsFuture(booking?.bookingTimeslot))
    .map(bookingToComponent);

  const pastBookings = bookings
    ?.filter(booking => booking && bookingIsEnded(booking?.bookingTimeslot))
    .map(bookingToComponent);

  const ongoingBookings = bookings
    ?.filter(
      booking =>
        booking &&
        !bookingIsFuture(booking?.bookingTimeslot) &&
        !bookingIsEnded(booking?.bookingTimeslot),
    )
    .map(bookingToComponent);

  const renderInner = () => {
    return (
      <>
        {adminIsManager && (
          <form className={styles.form} onSubmit={handleFormSubmitted}>
            <FormControl className={styles.searchInput}>
              <TextField
                disabled={!adminIsManager}
                variant="outlined"
                id="search-input"
                aria-describedby="helper-text"
                type="search"
                onChange={evt => setEmail(evt.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText id="helper-text">
                Search for a teachers bookings using their ef email address
              </FormHelperText>
            </FormControl>
          </form>
        )}
        <h1>Bookings</h1>
        {`Showing results for ${
          variables?.email === undefined ? 'all teachers' : variables.email
        }`}
        {ongoingBookings?.length ? (
          <>
            <h2>Ongoing</h2>
            {ongoingBookings}
          </>
        ) : null}
        <h2>Upcoming</h2>
        {futureBookings?.length ? futureBookings : 'No future bookings'}
        <h2>Past Week</h2>
        {pastBookings?.length ? pastBookings : 'No past bookings'}
        {JSON.stringify(error, null, 2)}
      </>
    );
  };

  return (
    <IndexLayout>
      <Page>
        <Container>{renderInner()}</Container>
      </Page>
    </IndexLayout>
  );
};

export default AdminLookupPage;
